import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import $api from "../../api/url";
import { authSlice } from "./authSlice";
import { errorMessage } from "../../api/helpers/scripts";
import axios from "axios";
import { ABORTED_ERROR } from "../../utils/vars/staticVariables";

export const getInstallPrices = createAsyncThunk(
  "management/get-install-price",
  async () => {
    try {
      const { data } = await $api.get(`/support/get-install-price/`);
      return data;
    } catch (error) {
      throw error;
    }
  },
);

export const getUserInfo = createAsyncThunk(
  "management/getUserInfo",
  async (id) => {
    try {
      const { data } = await $api.get(`/support/get-user-info/${Number(id)}/`);

      return data;
    } catch (error) {
      throw error;
    }
  },
);

export const getImpersonateUser = createAsyncThunk(
  "management/impersonateUser",
  async (email) => {
    try {
      const response = await $api.post("/users/get-impersonate-user-token/", {
        email,
      });
      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const fetchCategoryPriority = createAsyncThunk(
  "management/fetchCategoryPriority",
  async () => {
    try {
      const response = await $api.get("/category-positions/");
      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);


export const setCategoryPriority = createAsyncThunk(
    "management/setCategoryPriority",
    async (dataList) => {
      try {
        const response = await $api.post("/category-positions/", dataList);
        const { data, status } = response;

        return { data, status };
      } catch (error) {
        const { data, status } = error.response;

        return { data, status };
      }
    },
);



export const compensateUser = createAsyncThunk(
  "management/compensateUser",
  async (dataObj) => {
    try {
      const response = await $api.post(
        "/support/create-user-transaction/",
        dataObj,
      );
      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const switchUserStatus = createAsyncThunk(
  "management/switchBlockingUser",
  async (id) => {
    try {
      const response = await $api.post("/support/deactivate-user/", {
        user_id: id,
      });
      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const fetchStandardClickPrice = createAsyncThunk(
  "management/fetchStandardClickPrice",
  async () => {
    try {
      const response = await $api.get("/support/get-standart-click-price/");
      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const addPostbackStatus = createAsyncThunk(
  "management/addPostbackStatus",
  async (dataObj) => {
    try {
      const response = await $api.post(
        "/support/add-postback-status/",
        dataObj,
      );
      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const fetchTechnicalUsersList = createAsyncThunk(
  "management/fetchTechnicalUsersList",
  async (params, { signal, rejectWithValue }) => {
    try {
      const { data } = await $api.get(
        "support/get-technical-users-list?" + params,
        {
          signal,
        },
      );

      return data;
    } catch (error) {
      let err = errorMessage(error);

      if (axios.isCancel(error)) {
        return rejectWithValue();
      }

      throw new Error(err);
    }
  },
);

export const changeUserPassword = createAsyncThunk(
  "management/changeUserPassword",
  async (dataObj) => {
    try {
      const response = await $api.post(
        "/support/change-password-for-user/",
        dataObj,
      );
      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const changePersonalInfo = createAsyncThunk(
  "management/changePersonalInfo",
  async (dataObj) => {
    try {
      const response = await $api.post("/support/change-user-info/", dataObj);
      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const createTeam = createAsyncThunk(
  "management/createTeam",
  async (dataObj) => {
    try {
      const response = await $api.post(`/support/add-new-team/`, dataObj);

      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const getUserTgToken = createAsyncThunk(
  "management/getUserTgToken",
  async (id) => {
    try {
      const response = await $api.get(`/support/get-user-tg-token/${id}/`);
      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const setIntallPrice = createAsyncThunk(
  "management/setIntallPrice",
  async (dataObj) => {
    try {
      const response = await $api.post(
        "/support/set-install-price-for-user/",
        dataObj,
      );
      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const managementSlice = createSlice({
  name: "management",
  initialState: {
    impersonateUserPending: false,
    technicalUsersListPending: false,
    technicalUsersList: [],
    technicalUsersCount: null,

    userInfoObj: null,
    getUserInfoPending: false,

    getInstallPricesPending: false,
    installPricesList: [],

    changeUserPasswordPending: false,

    compensateUserPending: false,

    switchUserStatusPending: false,

    addPostbackStatusPending: false,

    setInstallPricePending: false,

    changePersonalInfoPending: false,

    getUserTgTokenPending: false,

    createTeamPending: false,

    standardClickPriceValue: null,

    categoryPriorityPending: false,
    categoryPriorityList: []
  },
  reducers: {
    setUserInfoObj: (state, action) => {
      state.userInfoObj = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getInstallPrices.pending, (state) => {
        state.getInstallPricesPending = true;
        state.installPricesList = [];
      })
      .addCase(getInstallPrices.fulfilled, (state, action) => {
        state.getInstallPricesPending = false;
        state.installPricesList = action.payload.install_prices.map(
          ({ id, description }) => ({ value: id, label: description }),
        );
      })
      .addCase(getInstallPrices.rejected, (state, action) => {
        state.getInstallPricesPending = false;
        state.installPricesList = [];
      })

      .addCase(createTeam.pending, (state) => {
        state.createTeamPending = true;
      })
      .addCase(createTeam.fulfilled, (state, action) => {
        state.createTeamPending = false;
      })
      .addCase(createTeam.rejected, (state, action) => {
        state.createTeamPending = false;
      })

      .addCase(fetchCategoryPriority.pending, (state) => {
        state.categoryPriorityPending = true;
        state.categoryPriorityList = [];
      })
      .addCase(fetchCategoryPriority.fulfilled, (state, action) => {
        state.categoryPriorityPending = false;
        console.log('action---=--', action.payload?.data)
        // state.categoryPriorityList = action.payload;
        state.categoryPriorityList = action.payload?.data;
      })
      .addCase(fetchCategoryPriority.rejected, (state, action) => {
        state.categoryPriorityPending = true;
        state.categoryPriorityList = [];
      })

      .addCase(fetchStandardClickPrice.pending, (state) => {
        state.standardClickPriceValue = null;
      })
      .addCase(fetchStandardClickPrice.fulfilled, (state, action) => {
        state.standardClickPriceValue =
          action.payload?.data?.standart_click_price;
      })
      .addCase(fetchStandardClickPrice.rejected, (state, action) => {
        state.standardClickPriceValue = false;
      })

      .addCase(getUserTgToken.pending, (state) => {
        state.getUserTgTokenPending = true;
      })
      .addCase(getUserTgToken.fulfilled, (state, action) => {
        state.getUserTgTokenPending = false;
      })
      .addCase(getUserTgToken.rejected, (state, action) => {
        state.getUserTgTokenPending = false;
      })

      .addCase(changePersonalInfo.pending, (state) => {
        state.changePersonalInfoPending = true;
      })
      .addCase(changePersonalInfo.fulfilled, (state, action) => {
        state.changePersonalInfoPending = false;
      })
      .addCase(changePersonalInfo.rejected, (state, action) => {
        state.changePersonalInfoPending = false;
      })

      .addCase(switchUserStatus.pending, (state) => {
        state.switchUserStatusPending = true;
      })
      .addCase(switchUserStatus.fulfilled, (state, action) => {
        state.switchUserStatusPending = false;
      })
      .addCase(switchUserStatus.rejected, (state, action) => {
        state.switchUserStatusPending = false;
      })

      .addCase(setIntallPrice.pending, (state) => {
        state.setInstallPricePending = true;
      })
      .addCase(setIntallPrice.fulfilled, (state, action) => {
        state.setInstallPricePending = false;
      })
      .addCase(setIntallPrice.rejected, (state, action) => {
        state.setInstallPricePending = false;
      })

      .addCase(addPostbackStatus.pending, (state) => {
        state.addPostbackStatusPending = true;
      })
      .addCase(addPostbackStatus.fulfilled, (state, action) => {
        state.addPostbackStatusPending = false;
      })
      .addCase(addPostbackStatus.rejected, (state, action) => {
        state.addPostbackStatusPending = false;
      })

      .addCase(changeUserPassword.pending, (state) => {
        state.changeUserPasswordPending = true;
      })
      .addCase(changeUserPassword.fulfilled, (state, action) => {
        state.changeUserPasswordPending = false;
      })
      .addCase(changeUserPassword.rejected, (state, action) => {
        state.changeUserPasswordPending = false;
      })

      .addCase(compensateUser.pending, (state) => {
        state.compensateUserPending = true;
      })
      .addCase(compensateUser.fulfilled, (state, action) => {
        state.compensateUserPending = false;
      })
      .addCase(compensateUser.rejected, (state, action) => {
        state.compensateUserPending = false;
      })

      .addCase(getImpersonateUser.pending, (state) => {
        state.impersonateUserPending = true;
      })
      .addCase(getImpersonateUser.fulfilled, (state, action) => {
        state.impersonateUserPending = false;
      })
      .addCase(getImpersonateUser.rejected, (state, action) => {
        state.impersonateUserPending = false;
      })

      .addCase(getUserInfo.pending, (state) => {
        state.getUserInfoPending = true;
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.getUserInfoPending = false;
        state.userInfoObj = action.payload;
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.getUserInfoPending = false;
      })

      .addCase(fetchTechnicalUsersList.pending, (state) => {
        state.technicalUsersList = [];
        state.technicalUsersListPending = true;
      })
      .addCase(fetchTechnicalUsersList.fulfilled, (state, { payload }) => {
        const { results, count } = payload;

        state.technicalUsersList = results;
        state.technicalUsersCount = count;
        state.technicalUsersListPending = false;
      })
      .addCase(fetchTechnicalUsersList.rejected, (state, { error }) => {
        const { message } = error;

        state.technicalUsersListPending = message === ABORTED_ERROR;
        state.technicalUsersList = [];

        if (message !== ABORTED_ERROR) {
          state.technicalUsersCount = 0;
        }
      });
  },
});

export const { setUserInfoObj } = managementSlice.actions;

export const managementSelector = (state) => state.management;

export default managementSlice.reducer;
