import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useWindowWidth } from "../../../hooks/useWindowWidth";
import { fetchAppListWithSearch } from "../../../redux/slices/applicationSlice";
import {getIsAllowedStatisticsAccess, getIsStaff} from "../../../storage/storage";
import CustomSkeleton from "../../../components/CustomSkeleton/CustomSkeleton";
import EmptyList from "../../../components/Blocks/EmptyList/EmptyList";
import s from "./appCatalog.module.scss";
import AppCard from "./AppCard/AppCard";
import {
  fetchGeosList,
  globalDataSelector,
  setAdaptiveMenu,
} from "../../../redux/slices/globalDataSlice";
import { setTopUpBalanceActive } from "../../../redux/slices/financeSlice";
import GlobalSvgSelector from "../../../components/GlobalSvgSelector/GlobalSvgSelector";
import LanguageDropdown from "../../../components/partials/LanguageDropdown/LanguageDropdown";
import GradientSearch from "../../../components/partials/GradientSearch/GradientSearch";
import useGetSearchParams from "../../../hooks/useGetSearchParams";
import useSetSearchParams from "../../../hooks/useSetSearchParams";
import { useDebounce } from "../../../hooks/useDebounce";
import { search } from "../Statistics/ClicksStats/ClicksStats";
import {managementSelector} from "../../../redux/slices/managementSlice";

const AppCatalog = () => {
  const dispatch = useDispatch();
  const { appList, appListLoading } = useSelector((state) => state.application);
  const { isCollapsedSidebar, isAdaptiveMenuActive, geoListPending } =
    useSelector(globalDataSelector);
  const { standardClickPriceValue } = useSelector(managementSelector);

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const { search: searchValue } = useGetSearchParams();

  const updateSearchParams = useSetSearchParams(setSearchParams);

  const [name, setName] = useDebounce((value) => {
    if (value) {
      updateSearchParams(search, value);
    } else {
      updateSearchParams(search, value, true);
    }
  }, 600);

  const filteredAppList = useMemo(() => {
    if (getIsStaff()) {
      return appList.filter((item) => item?.is_alive === true);
    }
    return appList;
  }, [appList]);

  const width = useWindowWidth();

  useEffect(() => {
    dispatch(fetchAppListWithSearch(searchParams));
  }, [dispatch, searchParams]);

  useEffect(() => {
    dispatch(fetchGeosList());
  }, [dispatch]);

  useEffect(() => {
    if (!searchValue) {
      setName("");
    } else {
      setName(searchValue);
    }
  }, [searchParams]);

  let skeletonsCount;

  if (isCollapsedSidebar === "true") {
    if (width < 2288) {
      skeletonsCount = 9;
    }
    if (width >= 2288 && width < 2815) {
      skeletonsCount = 12;
    }

    if (width >= 2815 && width < 3492) {
      skeletonsCount = 15;
    }

    if (width > 3341 && width < 3868) {
      skeletonsCount = 18;
    }

    if (width > 3868) {
      skeletonsCount = 21;
    }
  } else {
    if (width < 2438) {
      skeletonsCount = 9;
    }
    if (width > 2437 && width < 2965) {
      skeletonsCount = 12;
    }

    if (width > 2964 && width < 3492) {
      skeletonsCount = 15;
    }

    if (width > 3491 && width < 4019) {
      skeletonsCount = 18;
    }

    if (width > 4018) {
      skeletonsCount = 21;
    }
  }

  const skeletons = (styles) => {
    return [...new Array(skeletonsCount)].map((_, index) => (
      <CustomSkeleton styles={styles} key={index} />
    ));
  };

  const pageWidth = useWindowWidth();

  return (
    <div
      className={`${s.appCatalogWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
    >
      <div className={s.pageHeaderWrapper}>
        <div className={s.pageHeaderContainer}>
          <div className={s.staticElements}>
            <div className={s.leftSideWrapper}>
              <button
                className={s.walletBtn}
                onClick={() => {
                  dispatch(setAdaptiveMenu(false));
                  dispatch(setTopUpBalanceActive(true));
                }}
              >
                <GlobalSvgSelector id="wallet" />
              </button>
              <Link className={s.walletBtn} to="/account">
                <GlobalSvgSelector id="account-gear-icon" />
              </Link>
              {(getIsStaff() || getIsAllowedStatisticsAccess()) && (
                  <Link to='/management' className={s.standardInstallPrice}>
                    <GlobalSvgSelector id='standard-install-price-icon'/>
                    <span>$ {standardClickPriceValue || "----" }</span>
                  </Link>
              )}
              <LanguageDropdown />
            </div>

            <button
              className={s.menuBtn}
              onClick={() => dispatch(setAdaptiveMenu(!isAdaptiveMenuActive))}
            >
              <GlobalSvgSelector id="burger-menu" />
            </button>
          </div>

          <div className={s.changingElements}>
            <GradientSearch value={name} setValue={setName} />
          </div>
        </div>
      </div>

      <div className={s.pageContent}>
        <div className={s.appCatalog}>
          {!appListLoading && !geoListPending && filteredAppList.length < 1 ? (
            <EmptyList />
          ) : (
            <div
              className={`${s.content} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
            >
              {appListLoading || geoListPending
                ? skeletons(s.skeletonCard)
                : filteredAppList.map((item) => (
                    <AppCard
                      key={item.id}
                      {...item}
                      navigate={navigate}
                      pageWidth={pageWidth}
                    />
                  ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppCatalog;
