import React from "react";
import s from "./primaryBorderBtn.module.scss";

const PrimaryBtn = ({ action, text, pending, submit, disabled, className }) => {
  return (
    <button
      type={submit ? "submit" : "button"}
      className={`${s.blueBorderBtn} ${className || ""}`}
      disabled={!disabled ? pending : disabled}
      onClick={action}
    >
      {text}
    </button>
  );
};

export default PrimaryBtn;
