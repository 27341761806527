import { useDispatch, useSelector } from "react-redux";
import {
  exportBalances,
  exportTransactions,
  fetchTransactions,
  financeSelector,
  setTopUpBalanceActive,
} from "../../../redux/slices/financeSlice";
import { authSelector } from "../../../redux/slices/authSlice";
import {
  fetchUsersList,
  globalDataSelector,
  setAdaptiveMenu,
  setNotifyPopup,
} from "../../../redux/slices/globalDataSlice";
import { Link, useSearchParams } from "react-router-dom";
import useGetSearchParams from "../../../hooks/useGetSearchParams";
import {
  INVALID_PAGE_ERROR,
  PURPOSE_REFILL,
  PURPOSE_WRITE_OFF,
  snackbar_error,
  snackbar_info,
  snackbar_success,
} from "../../../utils/vars/staticVariables";
import useSetSearchParams from "../../../hooks/useSetSearchParams";
import React, { useEffect, useMemo, useRef } from "react";

import { useSelectOptions } from "../../../utils/helpers/useSelectHelpers";
import { formExcelHandler } from "../../../utils/helpers/handlers";
import {
  created_at_after,
  created_at_before,
  page,
  purpose,
  user,
} from "../Statistics/ClicksStats/ClicksStats";
import { getDefaultParams } from "../../../api/helpers/scripts";
import s from "./transactions.module.scss";
import MultiSelect from "../../../components/Blocks/Selects/MultiSelect/MultiSelect";
import { MuiDatePicker } from "../../../components/Blocks/MuiDatePicker/MuiDatePicker";
import {
  formatTimestampToServerDate,
  stringToDayJs,
} from "../../../utils/helpers/formatTime";
import Pagination from "../../../components/Pagination/Pagination";
import { params } from "../../../utils/vars/params";
import RefillOption from "./TransactionsOptions/RefillOption";
import WriteOffOption from "./TransactionsOptions/WriteOffOption";
import GlobalSvgSelector from "../../../components/GlobalSvgSelector/GlobalSvgSelector";
import useCustomSnackbar from "../../../hooks/useCustomSnackbar";
import { useSnackbar } from "notistack";
import { hasNonEmptyParams } from "../../../utils/helpers/hasNoneEmptyParams";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "../../../components/partials/LanguageDropdown/LanguageDropdown";
import {
  getIsAllowedStatisticsAccess,
  getIsStaff,
} from "../../../storage/storage";
import {managementSelector} from "../../../redux/slices/managementSlice";

const Transactions = () => {
  const underlineRef = useRef(null);

  const dispatch = useDispatch();

  const { exportTransactionsPending, exportBalancesPending, totalCount } =
    useSelector(financeSelector);

  const { isPartner } = useSelector(authSelector);
  const { usersList, isCollapsedSidebar, isAdaptiveMenuActive } =
    useSelector(globalDataSelector);
  const { standardClickPriceValue } = useSelector(managementSelector);


  const [searchParams, setSearchParams] = useSearchParams();

  const showSnackbar = useCustomSnackbar();
  const { closeSnackbar } = useSnackbar();

  const {
    page: pageValue,
    created_at_after: createdAtAfterParam,
    created_at_before: createdAtBeforeParam,
    user: userParam,
    purpose: purposeParam,
  } = useGetSearchParams();

  const { t, i18n } = useTranslation();

  const purposeContent = isPartner
    ? PURPOSE_WRITE_OFF
    : purposeParam || PURPOSE_REFILL;

  const updateSearchParams = useSetSearchParams(setSearchParams);

  const selectedUser = useMemo(() => {
    return userParam ? { value: userParam, label: userParam } : null;
  }, [userParam]);

  useEffect(() => {
    const activeBtn = document.querySelector(`.${s.active}`);
    if (activeBtn && underlineRef.current) {
      const { left, width } = activeBtn.getBoundingClientRect();
      const parentLeft = activeBtn.parentElement.getBoundingClientRect().left;
      underlineRef.current.style.width = `${width}px`;
      underlineRef.current.style.transform = `translateX(${left - parentLeft}px)`;
    }
  }, [purposeContent, isPartner, i18n.language]);

  useEffect(() => {
    if (getIsStaff() || getIsAllowedStatisticsAccess()) {
      dispatch(fetchUsersList());
    }
  }, [dispatch]);

  const usersListOptions = useSelectOptions(usersList, "email", "email");

  const onExportTransactions = async () => {
    const exportTransactionsSnackbar = showSnackbar(
      t("transactions_export_processing"),
      snackbar_info,
      true,
    );

    const exportTranscationsResult = await dispatch(
      exportTransactions(searchParams),
    );

    if (exportTranscationsResult?.payload?.status === 200) {
      formExcelHandler(exportTranscationsResult?.payload?.data, "transactions");
      closeSnackbar(exportTransactionsSnackbar);
      showSnackbar(t("transactions_report_downloaded"), snackbar_success);
    } else {
      closeSnackbar(exportTransactionsSnackbar);
      showSnackbar(t("failed_transactions_export"), snackbar_error);
    }
  };

  const onExportBalances = async () => {
    const exportBalancesSnackbar = showSnackbar(
      t("balances_export_processing"),
      snackbar_info,
      true,
    );

    const exportBalancesResult = await dispatch(exportBalances(searchParams));

    if (exportBalancesResult?.payload?.status === 200) {
      formExcelHandler(exportBalancesResult?.payload?.data, "transactions");
      closeSnackbar(exportBalancesSnackbar);
      showSnackbar(t("balances_report_downloaded"), snackbar_success);
    } else {
      closeSnackbar(exportBalancesSnackbar);
      showSnackbar(t("failed_balances_export"), snackbar_error);
    }
  };

  // useEffect(() => {
  //   dispatch(setNotifyPopup(true));
  // }, [dispatch]);

  useEffect(() => {
    if (isPartner === false) {
      if (
        purposeContent !== PURPOSE_REFILL &&
        purposeContent !== PURPOSE_WRITE_OFF
      ) {
        updateSearchParams(purpose, PURPOSE_REFILL);
      }
    }
    if (isPartner === true) {
      updateSearchParams(purpose, PURPOSE_WRITE_OFF);
    }
  }, [isPartner]);

  useEffect(() => {
    const promise = dispatch(
      fetchTransactions(
        getDefaultParams({
          searchParams,
          defaultPageSize: 25,
          purpose: purposeContent,
        }),
      ),
    );

    promise.unwrap().catch((err) => {
      if (err?.message === INVALID_PAGE_ERROR) {
        updateSearchParams(page, 1);
      }
    });

    return () => {
      promise.abort();
    };
  }, [dispatch, searchParams]);

  const clearSearchParams = () => {
    setSearchParams(new URLSearchParams());
  };

  return (
    <div
      className={`${s.transactionsWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
    >
      <div className={s.pageHeaderWrapper}>
        <div className={s.pageHeaderContainer}>
          <div className={s.staticElements}>
            <div className={s.leftSideWrapper}>
              <button
                className={s.walletBtn}
                onClick={() => {
                  dispatch(setAdaptiveMenu(false));
                  dispatch(setTopUpBalanceActive(true));
                }}
              >
                <GlobalSvgSelector id="wallet" />
              </button>
              <Link className={s.walletBtn} to="/account">
                <GlobalSvgSelector id="account-gear-icon" />
              </Link>
              {(getIsStaff() || getIsAllowedStatisticsAccess()) && (
                  <Link to='/management' className={s.standardInstallPrice}>
                    <GlobalSvgSelector id='standard-install-price-icon'/>
                    <span>$ {standardClickPriceValue || "----" }</span>
                  </Link>
              )}
              <LanguageDropdown />
            </div>

            <button
              className={s.menuBtn}
              onClick={() => dispatch(setAdaptiveMenu(!isAdaptiveMenuActive))}
            >
              <GlobalSvgSelector id="burger-menu" />
            </button>
          </div>

          {isPartner === false && (
            <div className={s.changingElements}>
              <button
                onClick={() => dispatch(setTopUpBalanceActive(true))}
                className={s.primaryBtn}
              >
                {t("top_up")}
              </button>
            </div>
          )}
        </div>
      </div>

      <div className={s.pageContent}>
        <div className={s.switchPageContentContainer}>
          {isPartner === false && (
            <button
              className={`${s.pageBtn} ${
                purposeContent === PURPOSE_REFILL ? s.active : ""
              }`}
              onClick={() => {
                updateSearchParams(purpose, PURPOSE_REFILL);
                updateSearchParams(page, 1);
              }}
            >
              <GlobalSvgSelector id="recharge-icon" />
              {t("refills")}
            </button>
          )}

          <button
            className={`${s.pageBtn} ${
              purposeContent === PURPOSE_WRITE_OFF ? s.active : ""
            }`}
            onClick={() => {
              updateSearchParams(purpose, PURPOSE_WRITE_OFF);
              updateSearchParams(page, 1);
            }}
          >
            <GlobalSvgSelector id="write-off-icon" />
            {t("write_offs")}
          </button>
          <div className={s.underline} ref={underlineRef}></div>
        </div>

        <div className={s.filtersWrapper}>
          <div className={s.filters}>
            {(getIsStaff() || getIsAllowedStatisticsAccess()) && (
              <div className={s.btnsWrapper}>
                <button
                  className={s.blueBorderIconBtn}
                  onClick={onExportBalances}
                  disabled={exportBalancesPending}
                >
                  <GlobalSvgSelector id="download-btn-icon" />
                  {t("export_balances")}
                </button>
                <button
                  className={s.blueBorderIconBtn}
                  onClick={onExportTransactions}
                  disabled={exportTransactionsPending}
                >
                  <GlobalSvgSelector id="download-btn-icon" />
                  {t("export_transactions")}
                </button>
              </div>
            )}
            <div
              className={s.filter}
              style={
                !getIsStaff() && !getIsAllowedStatisticsAccess()
                  ? { width: "100%" }
                  : null
              }
            >
              {(getIsStaff() || getIsAllowedStatisticsAccess()) && (
                <div className={s.selectWrapper}>
                  <div className={s.selectIconWrapper}>
                    <GlobalSvgSelector id="user-select-icon" />
                  </div>
                  <MultiSelect
                    isClearable={true}
                    options={usersListOptions}
                    value={selectedUser}
                    setSelectedOptions={(option) => {
                      if (option) {
                        const { value } = option;
                        updateSearchParams(user, value);
                      } else {
                        updateSearchParams(user, "", true);
                      }
                    }}
                    placeholder={t("choose_user")}
                    isMulti={false}
                  />
                </div>
              )}

              <div className={s.datePickersWrapper}>
                <div className={s.datePicker}>
                  <MuiDatePicker
                    label={t("date_from")}
                    value={stringToDayJs(createdAtAfterParam)}
                    onChange={(e) =>
                      updateSearchParams(
                        created_at_after,
                        formatTimestampToServerDate(e?.toString()),
                      )
                    }
                  />
                </div>
                <div className={s.datePicker}>
                  <MuiDatePicker
                    label={t("date_to")}
                    value={stringToDayJs(createdAtBeforeParam)}
                    onChange={(e) => {
                      updateSearchParams(
                        created_at_before,
                        formatTimestampToServerDate(e?.toString()),
                      );
                    }}
                  />
                </div>
              </div>

              {hasNonEmptyParams(searchParams) && (
                <div className={s.cleanBtnContainer}>
                  <button
                    className={s.blueBorderBtn}
                    onClick={clearSearchParams}
                  >
                    {t("clean_button")}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={s.pageInnerContent}>
          {purposeContent === PURPOSE_REFILL && (
            <RefillOption updateSearchParams={updateSearchParams} />
          )}

          {purposeContent === PURPOSE_WRITE_OFF && (
            <WriteOffOption updateSearchParams={updateSearchParams} />
          )}
        </div>

        <div
          className={`${s.paginationContainer} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`}
        >
          {totalCount > 25 && (
            <Pagination
              currentPage={parseInt(pageValue) || 1}
              setCurrentPage={(page) => {
                updateSearchParams(params.page, page);
              }}
              total={totalCount}
              limit={25}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Transactions;
