import React from "react";
import GlobalSvgSelector from "../../../components/GlobalSvgSelector/GlobalSvgSelector";
import { faq, lessons } from "../../../utils/vars/documentation";
import s from "./documentation.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  globalDataSelector,
  setAdaptiveMenu,
} from "../../../redux/slices/globalDataSlice";
import { setTopUpBalanceActive } from "../../../redux/slices/financeSlice";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "../../../components/partials/LanguageDropdown/LanguageDropdown";
import {getIsAllowedStatisticsAccess, getIsStaff} from "../../../storage/storage";
import {managementSelector} from "../../../redux/slices/managementSlice";

const Documentation = () => {
  const { isCollapsedSidebar, isAdaptiveMenuActive } =
    useSelector(globalDataSelector);
  const { standardClickPriceValue } = useSelector(managementSelector);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  return (
    <div
      className={`${s.documentationWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
    >
      <div className={s.pageHeaderWrapper}>
        <div className={s.pageHeaderContainer}>
          <div className={s.staticElements}>
            <div className={s.leftSideWrapper}>
              <button
                className={s.walletBtn}
                onClick={() => {
                  dispatch(setAdaptiveMenu(false));
                  dispatch(setTopUpBalanceActive(true));
                }}
              >
                <GlobalSvgSelector id="wallet" />
              </button>
              <Link className={s.walletBtn} to="/account">
                <GlobalSvgSelector id="account-gear-icon" />
              </Link>
              {(getIsStaff() || getIsAllowedStatisticsAccess()) && (
                  <Link to='/management' className={s.standardInstallPrice}>
                    <GlobalSvgSelector id='standard-install-price-icon'/>
                    <span>$ {standardClickPriceValue || "----" }</span>
                  </Link>
              )}
              <LanguageDropdown />
            </div>

            <button
              className={s.menuBtn}
              onClick={() => dispatch(setAdaptiveMenu(!isAdaptiveMenuActive))}
            >
              <GlobalSvgSelector id="burger-menu" />
            </button>
          </div>
        </div>
      </div>
      <div className={s.pageContent}>
        <div className={s.documentation}>
          <div>
            <div className={s.chapterHeader}>
              <p className={s.name}>{t(faq.name)}</p>
              <p className={s.desc}>{t(faq.desc)}</p>
            </div>
            <ul className={s.faqLinkList}>
              {faq.linksList.map(({ link, name, image, desc }, index) => (
                <li key={index} className={s.faqCard}>
                  <a href={link} target="_blank" rel="noreferrer">
                    <div
                      className={s.cardImg}
                      style={{
                        backgroundImage: `url(${image})`,
                      }}
                    ></div>
                  </a>
                  <div className={s.body}>
                    <div className={s.name}>{t(name)}</div>
                    <div className={s.desc}>{t(desc)}</div>
                    <div className={s.linkContainer}>
                      <a
                        href={link}
                        target="_blank"
                        className={s.link}
                        rel="noreferrer"
                      >
                        {t("follow")}{" "}
                        <GlobalSvgSelector id="follow-link-faq-icon" />
                      </a>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <div className={s.chapterHeader}>
              <p className={s.name}>{t(lessons.name)}</p>
              <p className={s.desc}>{t(lessons.desc)}</p>
            </div>
            <ul className={s.lessonsLinkList}>
              {lessons.linksList.map(({ link, name, image, desc }, index) => (
                <li key={index} className={s.lessonsCard}>
                  <div className={s.lessonTag}>
                    <GlobalSvgSelector id="faq-lesson-player-icon" /> Урок{" "}
                    {index + 1}
                  </div>
                  <a href={link} target="_blank" rel="noreferrer">
                    <div
                      className={s.cardImg}
                      style={{
                        backgroundImage: `url(${image})`,
                      }}
                    ></div>
                  </a>
                  <div className={s.body}>
                    <div className={s.name}>{t(name)}</div>
                    <div className={s.desc}>{t(desc)}</div>
                    <div className={s.linkContainer}>
                      <a
                        href={link}
                        target="_blank"
                        className={s.link}
                        rel="noreferrer"
                      >
                        {t("follow")}{" "}
                        <GlobalSvgSelector id="follow-link-faq-icon" />
                      </a>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documentation;
