import React from "react";
import s from "./footer.module.scss";
import GlobalSvgSelector from "../../GlobalSvgSelector/GlobalSvgSelector";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { globalDataSelector } from "../../../redux/slices/globalDataSlice";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { isCollapsedSidebar } = useSelector(globalDataSelector);

  const { t } = useTranslation();

  return (
    // className={`${s.clicksStatsWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
    <div
      // className={s.footerWrapper}
      className={`${s.footerWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
    >
      <Link className={s.logoWrapper} to="/my-flows">
        <GlobalSvgSelector id="new-logo-icon" />
      </Link>
      <div className={s.infoWrapper}>
        <div className={s.companyInfo}>
          <p className={s.name}>Wild Wild Apps</p>
          <p className={s.rights}>© Copyright 2024 All rights reserved</p>
        </div>
        <div className={s.servicesLinks}>
          <div className={s.linkDetails}>
            <p className={s.linkTitle}>{t("our_services")}</p>
            <a
              href="https://t.me/yaroslava_support_wwa"
              target="_blank"
              className={s.pwaLink}
              rel="noreferrer"
            >
              PWA
            </a>
          </div>

          {/*<div className={s.linkDetails}>*/}
          {/*  <p className={s.linkTitle}>Приватность</p>*/}
          {/*  <a href="" target="_blank" className={s.privacyPolicyLink}>*/}
          {/*    Privacy Policy*/}
          {/*  </a>*/}
          {/*</div>*/}
        </div>
      </div>

      {/*<div className={s.socials}>*/}
      {/*  <a href="" target="_blank" className={s.socialLink}>*/}
      {/*    <GlobalSvgSelector id="telegram-social" />*/}
      {/*  </a>*/}
      {/*  <a href="" target="_blank" className={s.socialLink}>*/}
      {/*    <GlobalSvgSelector id="instagram-social" />*/}
      {/*  </a>*/}
      {/*  <a href="" target="_blank" className={s.socialLink}>*/}
      {/*    <GlobalSvgSelector id="linkedin-social" />*/}
      {/*  </a>*/}
      {/*</div>*/}
    </div>
  );
};

export default Footer;
