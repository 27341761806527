import React from "react";
import { useRoutes } from "./hooks/useRoutes";
import CustomSnackbarProvider from "./components/partials/SnackbarProvider/SnackbarProvider";
import { isUserAuthenticated } from "./redux/slices/authSlice";
// import "react-tooltip/dist/react-tooltip.css";
import s from "./assets/styles/app.module.scss";

function App() {
  const routes = useRoutes(isUserAuthenticated());

  return (
    <div className={s.appWrapper}>
      <CustomSnackbarProvider>{routes}</CustomSnackbarProvider>
    </div>
  );
}

export default App;
