export const unsufficientFundsNotifError = "notif_unsufficient_funds";

export const noDomainsNotifError = "notif_no_domains";

export const turnedOffPrelandFromCloacaNotif = "turned_off_preland_of_cloaca";

export const not_found_index_html = "not_found_index_html";

export const file_cyrillic_error = "file_cyrillic_error";

export const failed_to_upload_prelanding = "failed_to_upload_prelanding";

export const redirect_with_this_sub7_exists = "redirect_with_this_sub7_exists";
