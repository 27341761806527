import React from "react";
import CreatableSelect from "react-select/creatable";
import { isIPv6 } from "../../../../components/UiKit/Select/IpCreatableSelect/IpCreatableSelect";
import { useTranslation } from "react-i18next";

const SelectStyles = {
  control: (baseStyles, { isSelected, selectProps }) => ({
    ...baseStyles,
    background: "#171a21",
    minHeight: 48,
    borderRadius: 40,
    paddingLeft: 17,
    overflow: "hidden",
    cursor: "pointer",
    color: "#F2F5FA",
    "&:hover": {
      borderColor: selectProps.isInvalid ? "#D02533" : baseStyles.borderColor,
    },
    boxShadow: selectProps.isInvalid ? "none" : baseStyles.boxShadow,
    borderColor: selectProps.isInvalid
      ? "#D02533"
      : isSelected
        ? "#F2F5FA"
        : "#171a21",
  }),

  multiValueLabel: (provided, { data }) => ({
    ...provided,
    color: "#F2F5FA",
    // color: data.is_alive ? "#F2F5FA" : "#FF4136",
    // color: "#F2F5FA",
  }),

  multiValueRemove: (provided, state) => ({
    ...provided,
    color: "#476CFF",
    background: "none",
    // height: "40px",
    "&:hover": {
      background: "none",
      color: "#476CFF",
    },
    "& svg": {
      height: "20px", // Встановлення висоти SVG до 30 пікселів
      width: "20px", // За потреби, можна також встановити ширину
    },
  }),

  dropdownIndicator: (baseStyles, state) => ({
    ...baseStyles,
    background:
      state.isFocused || state.isHovered
        ? "rgba(255, 255, 255, 0.16)"
        : "rgba(255, 255, 255, 0.16)",
    padding: 4,
    marginRight: 16,
    borderRadius: "50%",
    svg: {
      fill: "#fff",
    },
  }),

  indicatorSeparator: () => ({
    display: "none", // Приховуємо палочку
  }),

  clearIndicator: (baseStyles) => ({
    ...baseStyles,
    color: "#476CFF", // Колір хрестика
    cursor: "pointer",
    padding: 4,
    marginRight: 16,

    borderRadius: "50%",
    "&:hover": {
      color: "#476CFF", // Колір хрестика при наведенні
    },
    // width: 24,
    // height: 25,
    background: "#FFFFFF29",

    "& svg": {
      // height: "25px", // Висота SVG-іконки
      // width: "25px", // Ширина SVG-іконки
      fill: "#fff", // Колір заливки SVG (впливає на колір хрестика)
      stroke: "#fff", // Колір обведення (якщо є)
    },
  }),

  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "rgba(225, 231, 255, 0.2)",
    padding: "4px 6px 4px 3px",
    fontSize: "17px",
    color: "#5D87FF",
    borderRadius: "5px",
  }),

  singleValue: (provided, state) => ({
    ...provided,
    // backgroundColor: '#2c3c5e',
    color: "#F2F5FA",
    textAlign: "left",
    // borderRadius: '5px',
  }),

  singleValueRemove: (provided, state) => ({
    ...provided,
    color: "red",
  }),

  menu: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: "#171a21",
    borderRadius: 25,
    zIndex: 1000 /* Високий z-index, щоб він накривав іконки */,
    // padding: "0 8px",
    // maxHeight: '250px', // Змініть на бажану висоту
    // overflowY: 'auto',
  }),

  menuList: (baseStyles) => ({
    ...baseStyles,
    // height: 380,
    borderRadius: 25,
    overflowX: "hidden",
    padding: "0 8px",

    backgroundColor: "#171a21",

    "&::-webkit-scrollbar": {
      width: 6,
      background: "#FFFFFF1A",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: 10,
      marginTop: "5px", // Відступ зверху для треку скролбару
      marginBottom: "5px", // Відступ знизу для треку скролбару
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 10,
      background: "#FFFFFF33",
    },
  }),

  placeholder: (styles) => ({
    ...styles,
    color: "#99A0AE",
    fontWeight: 400,
    fontSize: 15,
    position: "absolute",
  }),

  input: (styles) => ({
    ...styles,
    color: "white",
    "&::placeholder": {
      color: "green", // Колір placeholder для одиночного селекту
    },
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    padding: "2px 0 2px 0",
  }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const customStyle = {
      ...styles,
      color: isDisabled ? "#99A0AE" : "#F2F5FA",
      borderRadius: 20,
      backgroundColor: isFocused ? "#1E2437" : "#171a21",
      marginTop: 7,
      padding: "10px 8px 10px 16px",
      // borderBottom: "1px solid #5A6A85",
      cursor: isDisabled ? "default" : "pointer",
      "&:last-child": {
        marginBottom: 7,
        // borderBottom: "none",
      },
      "&:active": {
        backgroundColor: !isDisabled && "#1E2437",
      },
      "&:hover": {
        backgroundColor: !isDisabled && "#1E2437",
      },
    };

    if (data?.name === "app_list" && !data.is_alive) {
      customStyle.color = "#FF4136";
    }

    return customStyle;
  },
};

const IpSelect = ({
  selectedOption,
  setErrorMessage,
  isInvalid,
  onChange,
  onCreate,
  placeholder,
}) => {
  const { t } = useTranslation();

  return (
    <CreatableSelect
      formatCreateLabel={(inputValue) => `${t("create")} "${inputValue}"`}
      noOptionsMessage={() => t("add_ip")}
      styles={SelectStyles}
      value={selectedOption}
      onCreateOption={(ip) => {
        if (
          /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/.test(ip) ||
          isIPv6(ip)
        ) {
          onCreate(ip);
          setErrorMessage("");
        } else {
          setErrorMessage(t("ip_validation"));
        }
      }}
      onChange={(value) => onChange(value)}
      isClearable={true}
      isMulti
      placeholder={placeholder}
      isInvalid={isInvalid}
    />
  );
};

export default IpSelect;
