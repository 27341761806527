import React, {useEffect, useRef, useState} from 'react';
import CountUp from 'react-countup';

const AnimatedBalance = ({ end }) => {
    const [startBalance, setStartBalance] = useState(0) 
    const [endBalance, setEndBalance] = useState(end)
    
    const isMounted = useRef(false);

    useEffect(() => {
        if (isMounted.current && end !== 0) {
            setStartBalance(endBalance)
            setEndBalance(end)
        }

        isMounted.current = true;
    }, [end]);
    
    
    return (
        <CountUp
            start={startBalance}
            end={endBalance}
            // duration={0.5}
            duration={1}
            separator=","
            decimals={2}
            decimal="."
            prefix="$"
            easingFn={(t, b, c, d) => {
                return c * t / d + b;
            }}
        />
    );
};

export default AnimatedBalance;