import serviceReviewImg from "../../assets/img/FAQ_service_review.png";
import createFlowImg from "../../assets/img/FAQ_create_flow.png";
import fbPixelImg from "../../assets/img/FAQ_fb_pixel.png";
import trackerIntegrationImg from "../../assets/img/FAQ_tracker_integration.png";

import FAQ_lesson1 from "../../assets/img/FAQ_lesson1.png";
import FAQ_lesson2 from "../../assets/img/FAQ_lesson2.png";
import FAQ_lesson3 from "../../assets/img/FAQ_lesson3.png";
import FAQ_lesson4 from "../../assets/img/FAQ_lesson5.png";
import FAQ_lesson5 from "../../assets/img/FAQ_lesson5.png";
import FAQ_lesson6 from "../../assets/img/FAQ_lesson6.png";
import FAQ_lesson7 from "../../assets/img/FAQ_lesson7.png";

export const faq = {
  name: "documentation",
  desc: "documentation_description",
  linksList: [
    {
      name: "service_review",
      link: "https://stingy-trout-0f1.notion.site/WWA-303d7c630861460a8856e43a3e41e1c6?pvs=4",
      desc: "wwa_info",
      image: serviceReviewImg,
    },
    {
      name: "flow_creation",
      link: "https://stingy-trout-0f1.notion.site/WWA-fd9f67e383d742efae47ae8ecd1db81e?pvs=4",
      desc: "flow_creation_description",
      image: createFlowImg,
    },
    {
      name: "tracker_integration",
      link: "https://stingy-trout-0f1.notion.site/WWA-c15ff07456714895bcba8feb088bd709?pvs=4",
      desc: "tracker_integration_description",
      image: fbPixelImg,
    },
    {
      name: "FB PIXEL",
      link: "https://stingy-trout-0f1.notion.site/WWA-FB-PIXEL-6b6fd781231b4e909e27728e0e5a6750?pvs=4",
      desc: "fb_pixel_description",
      image: trackerIntegrationImg,
    },
  ],
};

export const lessons = {
  name: "lessons",
  desc: "lessons_description",
  linksList: [
    {
      name: "auth_and_balance",
      link: "https://www.youtube.com/watch?v=5LF8gC2E8m4&list=PL25QexTdjPU_tsDX2789kBTB8ltKW2eH6&index=1",
      desc: "",
      image: FAQ_lesson1,
    },
    {
      name: "flows_and_redirectors",
      link: "https://www.youtube.com/watch?v=RM2YQEz6kH8&list=PL25QexTdjPU_tsDX2789kBTB8ltKW2eH6&index=2",
      desc: "",
      image: FAQ_lesson2,
    },
    {
      name: "link_to_run",
      link: "https://www.youtube.com/watch?v=UbvFVgkt9sc&list=PL25QexTdjPU_tsDX2789kBTB8ltKW2eH6&index=3",
      desc: "",
      image: FAQ_lesson3,
    },
    {
      name: "pixel",
      link: "https://www.youtube.com/watch?v=5n7pjw5oNys&list=PL25QexTdjPU_tsDX2789kBTB8ltKW2eH6&index=4",
      desc: "",
      image: FAQ_lesson4,
    },
    {
      name: "pixel_bm_creation",
      link: "https://www.youtube.com/watch?v=OspbOrWppic&list=PL25QexTdjPU_tsDX2789kBTB8ltKW2eH6&index=5",
      desc: "",
      image: FAQ_lesson5,
    },
    {
      name: "pixels_add_on",
      link: "https://www.youtube.com/watch?v=VHLYje9aNqQ&list=PL25QexTdjPU_tsDX2789kBTB8ltKW2eH6&index=6",
      desc: "",
      image: FAQ_lesson6,
    },
    {
      name: "pixel_token_validity_check",
      link: "https://www.youtube.com/watch?v=-YwUAKD2fPo&list=PL25QexTdjPU_tsDX2789kBTB8ltKW2eH6&index=7",
      desc: "",
      image: FAQ_lesson7,
    },
  ],
};
