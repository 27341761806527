import React from "react";
// import s from "../Flows/EditFlowSettings/Steps/PrelandingStep/CreatePrelanding/createPrelanding.module.scss";
// import {setPrelandTitle} from "../../redux/slices/flowSlice";
import s from "./limitedInput.module.scss";
import { useTranslation } from "react-i18next";

const LimitedInput = ({
  value,
  action,
  placeholder = "placehoder_type_text",
  maxLength,
  fieldName,
  formik,
}) => {
  const { t } = useTranslation();

  return (
    <div className={s.inputContainer}>
      <input
        type="text"
        name={fieldName}
        className={`${s.textInput} ${
          formik.touched[fieldName] && formik.errors[fieldName] ? s.error : ""
        }`}
        placeholder={t(placeholder)}
        value={value}
        onChange={action}
        maxLength={maxLength}
      />
      {/*{value.length > 0 && (*/}
      <div className={s.lengthIndicator}>
        {value.length}/{maxLength}
      </div>
      {/*)}*/}
    </div>
  );
};

export default LimitedInput;
