import React from "react";
import s from "./appCard.module.scss";
import GlobalSvgSelector from "../../../../components/GlobalSvgSelector/GlobalSvgSelector";
import { Link } from "react-router-dom";
import MuiTooltip from "../../../../components/UiKit/MuiTooltip/MuiTooltip";
import AdaptiveMuiTooltip from "../../../../components/UiKit/MuiTooltip/AdaptiveMuiTooltip";
import customPrelandImg from "../../../../assets/img/customPrelandMockup.png";
import appIconMockup from "../../../../assets/img/appIconMockup.png";
import { useSelector } from "react-redux";
import { globalDataSelector } from "../../../../redux/slices/globalDataSlice";
import { useTranslation } from "react-i18next";

const AppCard = ({
  image,
  name,
  app_store_url,
  app_type,
  app_age,
  app_size,
  icon,
  top_geo,
  blocked_geo,
}) => {
  const { geoList } = useSelector(globalDataSelector);

  const { t } = useTranslation();

  return (
    <div className={s.appCardWrapper}>
      <div className={s.header}>
        <div className={s.headerContainer}>
          <div className={s.info}>
            <div
              className={`${s.appIcon} ${!icon && s.mocked}`}
              style={{
                backgroundImage: `url(${icon || appIconMockup})`,
              }}
            ></div>
            <div className={s.details}>
              <AdaptiveMuiTooltip title={name}>
                <span className={s.appName}>{name}</span>
              </AdaptiveMuiTooltip>
              <p className={s.type}>
                <GlobalSvgSelector id="gamepad-icon" /> {app_type}
              </p>
              <p className={s.size}>
                {app_size} <span>{app_age}</span>
              </p>
            </div>
          </div>
          <div className={s.actionWrapepr}>
            <div className={s.actions}>
              <a
                className={s.appStoreBtnLink}
                href={app_store_url}
                target="_blank"
                rel="noreferrer"
              >
                <MuiTooltip title={t("tooltip_text_app_store")}>
                  <GlobalSvgSelector id="app-store-icon" />
                </MuiTooltip>
              </a>
              <Link
                className={s.flowsLink}
                to={`/my-flows?application_name=${name || ""}`}
              >
                <MuiTooltip title={t("tooltip_text_flows")}>
                  <GlobalSvgSelector id="my-flows" />
                </MuiTooltip>
              </Link>
              <Link className={s.flowsLink} to="/my-flows/create-flow">
                <MuiTooltip title={t("tooltip_text_create_flow")}>
                  <GlobalSvgSelector id="createFlowAppCard" />
                </MuiTooltip>
              </Link>
            </div>
            <div className={s.blockedGeoWrapper}>
              <GlobalSvgSelector id="lock-icon" />
              <ul className={s.blockedGeoList}>
                {blocked_geo?.split(", ")?.map((geo) => (
                  <li key={geo}>{geo}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className={s.blockedGeoWrapper}>
          <GlobalSvgSelector id="lock-icon" />
          <ul className={s.blockedGeoList}>
            {blocked_geo?.split(", ")?.map((geo) => (
              <li key={geo}>{geo}</li>
            ))}
          </ul>
        </div>
      </div>
      <div
        className={`${s.body} ${!image && s.mocked}`}
        style={{
          backgroundImage: `url(${image || customPrelandImg})`,
        }}
      ></div>
      <div className={s.topGeoWrapper}>
        <div className={s.topGeoContainer}>
          <div className={s.topGeoTitle}>
            <GlobalSvgSelector id="top-geo-icon" />
            <span>{t("top_geo")}</span>
          </div>
          <div className={s.topGeoList}>
            {Array.from({ length: 5 }, (_, i) =>
              top_geo ? top_geo[i] : null,
            ).map((item, index) => {
              const foundGeo = geoList.find(({ code }) => code === item) || {};
              const { code = "", flag } = foundGeo;

              return (
                <div className={s.geoItem} key={index}>
                  <span
                    className={s.flagBg}
                    style={
                      flag
                        ? {
                            backgroundImage: `url(${flag})`,
                          }
                        : {
                            background: "grey",
                          }
                    }
                  ></span>
                  <span className={s.geoCode}>{code}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppCard;
