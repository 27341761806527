import React, { useState } from "react";
import s from "./interfaceApps.module.scss";
import GradientBorder from "../../../../../components/Blocks/GradientBorder/GradientBorder";
import EmptyList from "../../../../../components/Blocks/EmptyList/EmptyList";
import CustomSkeleton from "../../../../../components/CustomSkeleton/CustomSkeleton";
import { Link } from "react-router-dom";
import MuiTooltip from "../../../../../components/UiKit/MuiTooltip/MuiTooltip";
import GlobalSvgSelector from "../../../../../components/GlobalSvgSelector/GlobalSvgSelector";
import { useSelector } from "react-redux";
import { globalDataSelector } from "../../../../../redux/slices/globalDataSlice";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { useSpring, animated } from "@react-spring/web";
import PrimaryBtn from "../../../../../components/UiKit/Buttons/PrimaryBorderBtn/PrimaryBorderBtn";

const SortableRow = ({ app }) => {
  const { app_age, app_size, name, id, icon, image, app_store_url } = app;

  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useSortable({ id: String(id) }); // Конвертуємо id у рядок

  const style = useSpring({
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : "translate3d(0px, 0px, 0)",
    config: { tension: 0, friction: 20 },
  });

  return (
    <animated.tr
      ref={setNodeRef}
      style={{
        ...style,
        boxShadow: isDragging ? "6px 13px 14px 0px #0075FF33" : "none",
        transform: transform
          ? `translate3d(0, ${transform.y}px, 0)`
          : undefined,
      }}
      {...attributes}
      {...listeners}
      className={`${isDragging ? s.dragTr : ""}`}
    >
      <td className={s.dragIconTd}>
        <GlobalSvgSelector id="drag-n-drop-icon" />
      </td>
      <td className={s.appNameTd}>
        <div className={s.appInfo}>
          <img src={icon} alt="app" className={s.appIcon} />
          <div className={s.appDetails}>
            <p className={s.name}>{name}</p>
            <p className={s.size}>
              <span>{app_size}</span>
              {app_age}
            </p>
          </div>
        </div>
      </td>
      <td className={s.imgTd}>
        <div
          className={s.imgContainer}
          style={{
            backgroundImage: `url(${image})`,
          }}
        ></div>
      </td>
      <td className={s.actionsTd}>
        <a
          className={s.appStoreBtnLink}
          href={app_store_url}
          target="_blank"
          rel="noreferrer"
        >
          <MuiTooltip title="Открыть в App Store">
            <GlobalSvgSelector id="app-store-icon" />
          </MuiTooltip>
        </a>
      </td>
    </animated.tr>
  );
};

const InterfaceApps = ({}) => {
  const { appList, appListLoading } = useSelector((state) => state.application);

  const [applications, setApplications] = useState(appList);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10, // Дозволяє рух тільки після переміщення на 10px
      },
    }),
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!active || !over || active.id === over.id) return;

    setApplications((prevUsers) => {
      const oldIndex = prevUsers.findIndex(
        (user) => String(user.id) === active.id,
      );
      const newIndex = prevUsers.findIndex(
        (user) => String(user.id) === over.id,
      );

      if (oldIndex === -1 || newIndex === -1) return prevUsers;

      return arrayMove(prevUsers, oldIndex, newIndex);
    });
  };

  return (
    <div className={s.interfaceAppsWrapper}>
      <GradientBorder className={s.gradientBorderCustom} />
      <div className={s.tabHeader}>
        Порядок приложений для отображения в интерфейсе
      </div>
      <div className={s.pageInnerContent}>
        <div className={s.innerContentContainer}>
          {appList.length === 0 && !appListLoading ? (
            <EmptyList />
          ) : appListLoading ? (
            <section className={s.body}>
              <table>
                <tr>
                  <th></th>
                  <th>Приложение</th>
                  <th>Превью</th>
                  <th></th>
                </tr>
              </table>
              <div className={s.skeletonWrapper}>
                <CustomSkeleton styles={s.skeletonTable} />
              </div>
            </section>
          ) : (
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={applications.map((user) => String(user.id))}
                strategy={verticalListSortingStrategy}
              >
                <section className={s.body}>
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Приложение</th>
                        <th>Превью</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {applications.map((app) => (
                        <SortableRow key={app.id} app={app} />
                      ))}
                    </tbody>
                  </table>
                </section>
              </SortableContext>
            </DndContext>
          )}
        </div>
      </div>
      <div className={s.buttons}>
        <button
          className={s.whiteBorderBtn}
          type="button"
          // onClick={() => formik.resetForm()}
        >
          Отменить
        </button>
        <PrimaryBtn
          text="Сохранить"
          submit={true}
          // pending={changePasswordPending}
          className={s.customPrimaryBtn}
        />
      </div>
    </div>
  );
};

export default InterfaceApps;
