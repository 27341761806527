import React, { useEffect, useMemo, useState } from "react";
import {
  geoAppStatsOrderingList,
  params,
  sortDateOptions,
  usersStatsOrderingList,
} from "../../../../utils/vars/params";
import { useDispatch, useSelector } from "react-redux";
import {
  globalDataSelector,
  setAdaptiveMenu,
} from "../../../../redux/slices/globalDataSlice";
import { applicationSelector } from "../../../../redux/slices/applicationSlice";
import {
  fetchGeoAppStats,
  resetTotalCount,
  statisticsSelector,
} from "../../../../redux/slices/statisticsSlice";
import { Link, useSearchParams } from "react-router-dom";
import useGetSearchParams from "../../../../hooks/useGetSearchParams";
import useSetSearchParams from "../../../../hooks/useSetSearchParams";
import {
  useSearchParamsOptions,
  useSelectOptions,
} from "../../../../utils/helpers/useSelectHelpers";
import {
  application_name,
  created_at_after,
  created_at_before,
  geo,
  onSortDateHandler,
  page,
  sort,
} from "../../../Authorized/Statistics/ClicksStats/ClicksStats";
import { fetchGeoAppStatsFilters } from "../../../../api/helpers/fetchFilters";
import { getDefaultParams } from "../../../../api/helpers/scripts";
import { INVALID_PAGE_ERROR } from "../../../../utils/vars/staticVariables";
import s from "./geoAppStats.module.scss";
import MultiSelect from "../../../../components/Blocks/Selects/MultiSelect/MultiSelect";
import { MuiDatePicker } from "../../../../components/Blocks/MuiDatePicker/MuiDatePicker";
import {
  formatTimestampToServerDate,
  stringToDayJs,
} from "../../../../utils/helpers/formatTime";
import EmptyList from "../../../../components/Blocks/EmptyList/EmptyList";
import ParamsSort from "../../../../components/Blocks/ParamsSort/ParamsSort";
import CustomSkeleton from "../../../../components/CustomSkeleton/CustomSkeleton";
import Pagination from "../../../../components/Pagination/Pagination";
import { setTopUpBalanceActive } from "../../../../redux/slices/financeSlice";
import GlobalSvgSelector from "../../../../components/GlobalSvgSelector/GlobalSvgSelector";
import { PageSizeDropdown } from "../../../../components/UiKit/PageSizeDropdown/PageSizeDropdown";
import { onSetPageSize } from "../../../../utils/helpers/statisticsHelper";
import { hasNonEmptyParams } from "../../../../utils/helpers/hasNoneEmptyParams";
import FilterApplicationSelect from "../../../../components/Blocks/Selects/FilterApplicationSelect";
import GeoSelect from "../../../../components/Blocks/Selects/GeoSelect";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "../../../../components/partials/LanguageDropdown/LanguageDropdown";

const GeoAppStats = () => {
  const [sortDate, setSortDate] = useState(sortDateOptions[0]);
  const [selectedGeos, setSelectedGeos] = useState([]);

  const dispatch = useDispatch();

  const { geoList, isCollapsedSidebar, isAdaptiveMenuActive, pageSizeValue } =
    useSelector(globalDataSelector);
  const { appList } = useSelector(applicationSelector);

  const {
    statisticsList,
    loading,
    totalCount,
    totalInstalls,
    totalLeads,
    totalSales,
    totalClicks,
  } = useSelector(statisticsSelector);

  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    sort: sortValue,
    page: pageValue,
    created_at_after: createdAtAfterParam,
    created_at_before: createdAtBeforeParam,
  } = useGetSearchParams();

  const updateSearchParams = useSetSearchParams(setSearchParams);

  const [selectedApplications] = useSearchParamsOptions(searchParams, [
    application_name,
  ]);

  const geoListOptions = useMemo(() => {
    return geoList.map((item) => ({
      label: item.name,
      value: item.code,
      icon: item.flag,
    }));
  }, [geoList]);

  const appListOptions = useMemo(() => {
    return appList.map((item) => ({
      label: item.name,
      value: item.name,
      is_alive: item.is_alive,
      name: "app_list",
      icon: item.icon,
    }));
  }, [appList]);

  useEffect(() => {
    fetchGeoAppStatsFilters(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (
      createdAtAfterParam ||
      createdAtBeforeParam ||
      sortDate.value === "custom_date"
    ) {
      setSortDate(sortDateOptions.find((item) => item.value === "custom_date"));
    } else {
      if (!sortValue) {
        setSortDate(sortDateOptions[0]);
      } else {
        setSortDate(sortDateOptions.find(({ value }) => value === sortValue));
      }
    }
  }, [searchParams]);

  useEffect(() => {
    const newGeoListOptions = searchParams
      .get(geo)
      ?.split(",")
      .filter((value) => value !== "")
      .map((value) => {
        return geoListOptions.find((item) => item.value === value);
      });

    if (!newGeoListOptions || newGeoListOptions.length === 0) {
      setSelectedGeos([]);
    } else {
      setSelectedGeos(newGeoListOptions);
    }
  }, [searchParams, geoListOptions]);

  useEffect(() => {
    const promise = dispatch(
      fetchGeoAppStats(
        getDefaultParams({
          searchParams,
          sort,
          defaultPageSize: pageSizeValue,
        }),
      ),
    );

    promise.unwrap().catch((err) => {
      if (err?.message === INVALID_PAGE_ERROR) {
        updateSearchParams(page, 1);
      }
    });

    return () => {
      promise.abort();
    };
  }, [dispatch, searchParams, pageSizeValue]);

  useEffect(() => {
    return () => dispatch(resetTotalCount());
  }, [dispatch]);

  const handleSetPageSize = (size) => {
    onSetPageSize(dispatch, size);
  };

  const clearSearchParams = () => {
    setSearchParams(new URLSearchParams());
    setSelectedGeos([]);
  };

  const selectedDate = (() => {
    const foundOption = sortDateOptions.find(
      ({ value }) => value === sortDate?.value,
    );

    if (foundOption) {
      return { ...foundOption, label: t(foundOption.value) };
    }

    return null;
  })();

  return (
    <div
      className={`${s.geoAppStatsWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
    >
      <div className={s.pageHeaderWrapper}>
        <div className={s.pageHeaderContainer}>
          <div className={s.staticElements}>
            <div className={s.leftSideWrapper}>
              <button
                className={s.walletBtn}
                onClick={() => {
                  dispatch(setAdaptiveMenu(false));
                  dispatch(setTopUpBalanceActive(true));
                }}
              >
                <GlobalSvgSelector id="wallet" />
              </button>
              <Link className={s.walletBtn} to="/account">
                <GlobalSvgSelector id="account-gear-icon" />
              </Link>
              <LanguageDropdown />
            </div>

            <button
              className={s.menuBtn}
              onClick={() => dispatch(setAdaptiveMenu(!isAdaptiveMenuActive))}
            >
              <GlobalSvgSelector id="burger-menu" />
            </button>
          </div>
        </div>
      </div>

      <div className={s.pageContent}>
        <div className={s.filtersWrapper}>
          <div className={s.filters}>
            {
              <div className={s.selectDateWrapper}>
                <div className={s.selectIconWrapper}>
                  <GlobalSvgSelector id="date-select-icon" />
                </div>
                <MultiSelect
                  isClearable={false}
                  options={sortDateOptions.map((option) => {
                    return {
                      ...option,
                      label: t(option.value),
                    };
                  })}
                  setSelectedOptions={(value) =>
                    onSortDateHandler(value, updateSearchParams, setSortDate)
                  }
                  value={selectedDate}
                  placeholder={t("date")}
                  isMulti={false}
                />
              </div>
            }
            {sortDate?.value === "custom_date" && (
              <div className={s.datePickersWrapper}>
                <div className={s.datePicker}>
                  <MuiDatePicker
                    label={t("date_from")}
                    value={stringToDayJs(createdAtAfterParam)}
                    onChange={(e) =>
                      updateSearchParams(
                        created_at_after,
                        formatTimestampToServerDate(e?.toString()),
                      )
                    }
                  />
                </div>
                <div className={s.datePicker}>
                  <MuiDatePicker
                    label={t("date_to")}
                    value={stringToDayJs(createdAtBeforeParam)}
                    onChange={(e) => {
                      updateSearchParams(
                        created_at_before,
                        formatTimestampToServerDate(e?.toString()),
                      );
                    }}
                  />
                </div>
              </div>
            )}

            <div className={s.selectWrapper}>
              <div className={s.selectIconWrapper}>
                <GlobalSvgSelector id="app-select-icon" />
              </div>
              <FilterApplicationSelect
                isClearable={true}
                value={selectedApplications}
                options={appListOptions}
                onChange={(values) =>
                  updateSearchParams(
                    application_name,
                    values.map((el) => el.value).join(","),
                  )
                }
                placeholder={t("application")}
                isMulti={true}
              />
            </div>

            <div className={s.selectWrapper}>
              <div className={s.selectIconWrapper}>
                <GlobalSvgSelector id="geo-select-icon" />
              </div>
              <GeoSelect
                isClearable={true}
                value={selectedGeos}
                options={geoListOptions}
                onChange={(values) =>
                  updateSearchParams(
                    geo,
                    values.map((el) => el.value).join(","),
                  )
                }
                placeholder={t("geo")}
                isMulti={true}
              />
            </div>

            {hasNonEmptyParams(searchParams) && (
              <div className={s.cleanBtnContainer}>
                <button className={s.blueBorderBtn} onClick={clearSearchParams}>
                  {t("clean_button")}
                </button>
              </div>
            )}
          </div>
        </div>

        <div className={s.pageInnerContent}>
          {totalCount === 0 && !loading ? (
            <EmptyList />
          ) : loading ? (
            <section className={s.body}>
              <table>
                <thead>
                  <tr>
                    {usersStatsOrderingList.map(({ ordering, name }) => (
                      <th key={name}>
                        <div className={s.headerContent}>
                          {t(name)}
                          <ParamsSort
                            sortBy={ordering}
                            searchParams={searchParams}
                            setSearchParams={setSearchParams}
                          />
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
              </table>
              <div className={s.skeletonWrapper}>
                <CustomSkeleton styles={s.skeletonTable} />
              </div>
            </section>
          ) : (
            <section className={s.body}>
              <table>
                <thead>
                  <tr>
                    {geoAppStatsOrderingList.map(({ ordering, name }) => (
                      <th key={name}>
                        <div className={s.headerContent}>
                          {t(name)}
                          <ParamsSort
                            sortBy={ordering}
                            searchParams={searchParams}
                            setSearchParams={setSearchParams}
                          />
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {statisticsList?.map(
                    (
                      {
                        geo,
                        app,
                        total_installs,
                        total_leads,
                        total_sales,
                        total_clicks,
                      },
                      index,
                    ) => {
                      const geoFlag = geoList.find(
                        (geoItem) => geoItem.code === geo,
                      )?.flag;
                      const appIcon = appList.find(
                        (appItem) => appItem.name === app?.name,
                      )?.icon;

                      return (
                        <tr key={index} className={s.scrollTable}>
                          <td className={s.geoTd}>
                            <span className={s.value}>
                              <span
                                className={s.flagWrapper}
                                style={
                                  geoFlag
                                    ? {
                                        backgroundImage: `url(${geoFlag})`,
                                      }
                                    : {
                                        background: "grey",
                                      }
                                }
                              ></span>
                              <span>{geo}</span>
                            </span>
                          </td>
                          <td className={s.appTd}>
                            <span className={s.value}>
                              <span
                                className={s.iconWrapper}
                                style={
                                  appIcon
                                    ? {
                                        backgroundImage: `url(${appIcon})`,
                                      }
                                    : {
                                        background: "grey",
                                      }
                                }
                              ></span>
                              <span>{app?.name}</span>
                            </span>
                          </td>
                          <td>{app?.bundle}</td>
                          <td>{total_clicks}</td>
                          <td>{total_installs}</td>
                          <td>{total_leads}</td>
                          <td>{total_sales}</td>
                        </tr>
                      );
                    },
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <th>Всего</th>
                    <th></th>
                    <th></th>
                    <th>{totalClicks}</th>
                    <th>{totalInstalls}</th>
                    <th>{totalLeads}</th>
                    <th>{totalSales}</th>
                  </tr>
                </tfoot>
              </table>
            </section>
          )}
        </div>

        <div
          className={`${s.paginationContainerStatistics} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`}
        >
          <div className={s.countPageSizeContainer}>
            <div className={s.newRecordsCount}>
              {t("total")}: {!loading && <span>{totalCount}</span>}
            </div>
            <div className={s.pageSizeDropdownContainer}>
              <PageSizeDropdown
                setSize={handleSetPageSize}
                size={pageSizeValue}
              />
            </div>
          </div>
          {totalCount > pageSizeValue && (
            <Pagination
              currentPage={parseInt(pageValue) || 1}
              setCurrentPage={(page) => {
                updateSearchParams(params.page, page);
              }}
              total={totalCount}
              limit={pageSizeValue}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default GeoAppStats;
