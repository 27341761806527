import { languageOptions } from "../components/partials/LanguageDropdown/LanguageDropdown";

export const getCurrentLanguage = () => {
  const storedLanguage = localStorage.getItem("language");

  if (languageOptions.includes(storedLanguage)) {
    return storedLanguage;
  }

  return languageOptions[0];
};

export const switchLanguage = (value) => {
  localStorage.setItem("language", value);
};
