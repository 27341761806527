import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  globalDataSelector,
  setAdaptiveMenu,
} from "../../../../../../../redux/slices/globalDataSlice";
import {
  editPrelanding,
  fetchWheelStylesList,
  getPrelandingById,
  patchEditPrelanding,
  prelandingSelector,
  setAllPrelandingsList,
} from "../../../../../../../redux/slices/prelandingSlice";
import { Link, useNavigate, useParams } from "react-router-dom";
import useCustomSnackbar from "../../../../../../../hooks/useCustomSnackbar";
import {
  prelandingTypesList,
  snackbar_error,
  snackbar_info,
  snackbar_success,
} from "../../../../../../../utils/vars/staticVariables";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  noIndexHtml,
  noIndexHtmlFormikError, noLinkInIndexHtml,
  prelandingFileCyrillicError,
} from "../../../../../../../utils/vars/errorMessages";
import s from "../../../../../Prelandings/EditPrelanding/editPrelanding.module.scss";
import { setTopUpBalanceActive } from "../../../../../../../redux/slices/financeSlice";
import GlobalSvgSelector from "../../../../../../../components/GlobalSvgSelector/GlobalSvgSelector";
import GradientBorder from "../../../../../../../components/Blocks/GradientBorder/GradientBorder";
// import EditDefault from "../../../../../Prelandings/EditPrelanding/EditDefault/EditDefault";
// import EditCustom from "../../../../../Prelandings/EditPrelanding/EditCustom/EditCustom";
import { flowSettingsSelector } from "../../../../../../../redux/slices/flowSettingsSlice";
import EditDefault from "./EditDefault/EditDefault";
import EditCustom from "./EditCustom/EditCustom";
import { useTranslation } from "react-i18next";
import {
  file_cyrillic_error,
  not_found_index_html,
} from "../../../../../../../utils/vars/notificationsMessages";

const EditPrelanding = ({ setContent, choosePrelandingFormik }) => {
  const errorRefs = useRef({}); // Додано для збереження рефів полів з помилками

  const { currentEditPrelandingObj } = useSelector(flowSettingsSelector);

  const { wheelStylesList, prelandingObj, allPrelandingsList } =
    useSelector(prelandingSelector);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const showSnackbar = useCustomSnackbar();

  const { t } = useTranslation();

  const initialValues = {
    status: currentEditPrelandingObj?.status,
    name: currentEditPrelandingObj?.name || "",
    num_of_revolutions: 1,
    prelanding_type:
      currentEditPrelandingObj?.prelanding_type || prelandingTypesList[0].value,
    header_text: currentEditPrelandingObj?.header_text || "",
    rotation_button_text: currentEditPrelandingObj?.rotation_button_text || "",
    respin_button_text: currentEditPrelandingObj?.respin_button_text || "",
    style: currentEditPrelandingObj?.style || wheelStylesList[0]?.id,
    sectors:
      currentEditPrelandingObj?.sectors ||
      new Array(8).fill().map((_, index) => ({
        name: `Сектор ${index}`,
        value: "",
        is_win: index <= 0,
      })),
    bonus_button_text: currentEditPrelandingObj?.bonus_button_text || "",
    popup_win_text: currentEditPrelandingObj?.popup_win_text || "",
    winning_button_text: currentEditPrelandingObj?.winning_button_text || "",
    logo: null,
  };

  const editDefaultPrelandFormik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required(t("requiredField")),
      // header_text: Yup.string().required("Обязательное поле"),
      rotation_button_text: Yup.string().required(t("requiredField")),
      respin_button_text: Yup.string().required(t("requiredField")),
      sectors: Yup.array().of(
        Yup.object().shape({
          value: Yup.string().required(t("fill_sector_value")),
        }),
      ),
      bonus_button_text: Yup.string().required(t("requiredField")),
      winning_button_text: Yup.string().required(t("requiredField")),
      style: Yup.string().required(t("requiredField")),
    }),
    onSubmit: async (data) => {
      const editPrelandingResult = await dispatch(
        editPrelanding({ prelandObj: data, id: currentEditPrelandingObj?.id }),
      );
      if (editPrelandingResult?.payload?.status === 200) {
        const updatedPrelandingsList = [
          editPrelandingResult?.payload?.data,
          ...allPrelandingsList.filter(
            (item) => item.id !== editPrelandingResult?.payload?.data?.id,
          ),
        ];

        dispatch(setAllPrelandingsList(updatedPrelandingsList));

        choosePrelandingFormik.setFieldValue(
          "prelanding_id",
          editPrelandingResult?.payload?.data?.id,
        );

        showSnackbar(
          `${t("prelanding")} ${editPrelandingResult?.payload?.data?.name} ${t("is_edited")}`,
          snackbar_success,
        );
        showSnackbar(t("set_edited_prelanding"), snackbar_info);

        setContent("choose");
      } else {
        showSnackbar(t("failed_to_edit_prelanding"), snackbar_error);
      }
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const editCustomPrelandFormik = useFormik({
    initialValues: {
      prelanding_file: currentEditPrelandingObj?.prelanding_file,
      prelanding_type: currentEditPrelandingObj?.prelanding_type,
      name: currentEditPrelandingObj?.name,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required(t("requiredField")),
      prelanding_file: Yup.string().required(t("upload_zip_archive")),
    }),
    onSubmit: async (data, { setFieldError, validateField, setFieldValue }) => {
      if (
        data.prelanding_file === currentEditPrelandingObj?.prelanding_file &&
        data.name === currentEditPrelandingObj?.name
      ) {
        setFieldError("name", " ");
        setFieldError("prelanding_file", t("validation_make_changes"));

        return;
      }

      if (
        data.prelanding_file === currentEditPrelandingObj?.prelanding_file &&
        data.name !== currentEditPrelandingObj?.name
      ) {
        const patchRequestObj = {
          name: data.name,
          prelanding_type: data.prelanding_type,
        };

        const patchEditPrelandingResult = await dispatch(
          patchEditPrelanding({
            prelandObj: patchRequestObj,
            id: currentEditPrelandingObj?.id,
          }),
        );

        if (patchEditPrelandingResult?.payload?.status === 200) {
          const updatedPrelandingsList = [
            patchEditPrelandingResult?.payload?.data,
            ...allPrelandingsList.filter(
              (item) =>
                item.id !== patchEditPrelandingResult?.payload?.data?.id,
            ),
          ];

          dispatch(setAllPrelandingsList(updatedPrelandingsList));

          choosePrelandingFormik.setFieldValue(
            "prelanding_id",
            patchEditPrelandingResult?.payload?.data?.id,
          );

          showSnackbar(
            `${t("prelanding")} ${patchEditPrelandingResult?.payload?.data?.name} ${t("is_edited")}`,
            snackbar_success,
          );
          showSnackbar(t("set_edited_prelanding"), snackbar_info);

          setContent("choose");
        } else {
          showSnackbar(t("failed_to_edit_prelanding"), snackbar_error);
        }
        return;
      }

      const formData = new FormData();
      formData.append("prelanding_file", data.prelanding_file);
      formData.append("prelanding_type", data.prelanding_type);
      formData.append("name", data.name);

      const editPrelandingResult = await dispatch(
        editPrelanding({
          prelandObj: formData,
          id: currentEditPrelandingObj?.id,
        }),
      );
      if (editPrelandingResult?.payload?.status === 200) {
        const updatedPrelandingsList = allPrelandingsList.map((item) => {
          if (item.id === currentEditPrelandingObj?.id) {
            return {
              ...item,
              name: editPrelandingResult?.payload?.data?.name,
              prelanding_file:
                editPrelandingResult?.payload?.data?.prelanding_file,
            };
          }
          return item;
        });

        dispatch(setAllPrelandingsList(updatedPrelandingsList));

        choosePrelandingFormik.setFieldValue(
          "prelanding_id",
          editPrelandingResult?.payload?.data?.id,
        );

        showSnackbar(
          `${t("prelanding")} ${editPrelandingResult?.payload?.data?.name} ${t("is_edited")}`,
          snackbar_success,
        );
        showSnackbar(t("set_edited_prelanding"), snackbar_info);

        setContent("choose");
      } else if (editPrelandingResult?.payload?.data?.detail === noIndexHtml) {
        return setFieldError("prelanding_file", t(not_found_index_html));
      } else if (
          editPrelandingResult?.payload?.data?.detail === noLinkInIndexHtml
      ) {
        return setFieldError("prelanding_file", t('no_link_in_index_html'));
      } else if (
        editPrelandingResult?.payload?.data?.detail ===
        prelandingFileCyrillicError
      ) {
        return setFieldError("prelanding_file", t(file_cyrillic_error));
      } else {
        showSnackbar(t("failed_to_edit_prelanding"), snackbar_error);
      }
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  });

  useEffect(() => {
    // Функція для скролу до першого поля з помилкою з урахуванням фіксованого хедера
    const scrollToError = () => {
      const firstErrorKey = Object.keys(editDefaultPrelandFormik.errors)[0];
      if (firstErrorKey && errorRefs.current[firstErrorKey]) {
        const errorElement = errorRefs.current[firstErrorKey];
        const headerOffset = 80; // Висота фіксованого хедера
        const elementPosition =
          errorElement.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - headerOffset;

        // Перевірка, чи поле з помилкою знаходиться поза зоною видимості
        const isOutOfView =
          elementPosition < window.pageYOffset ||
          elementPosition > window.pageYOffset + window.innerHeight;

        if (isOutOfView) {
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      }
    };

    if (
      editDefaultPrelandFormik.isSubmitting &&
      Object.keys(editDefaultPrelandFormik.errors).length > 0
    ) {
      scrollToError();
    }
  }, [editDefaultPrelandFormik.isSubmitting, editDefaultPrelandFormik.errors]);

  return (
    <div className={s.editPrelandingWrapper}>
      {currentEditPrelandingObj?.prelanding_type === 1 && (
        <EditDefault
          formik={editDefaultPrelandFormik}
          setContent={setContent}
        />
      )}
      {currentEditPrelandingObj?.prelanding_type === 4 && (
        <EditCustom formik={editCustomPrelandFormik} setContent={setContent} />
      )}
    </div>
  );
};

export default EditPrelanding;
