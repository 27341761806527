import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import $api from "../../api/url";

export const fetchAppList = createAsyncThunk(
  "application/fetchAppList",
  async (_, thunkAPI) => {
    try {
      const response = await $api.get("/applications/");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchAppListWithSearch = createAsyncThunk(
  "application/fetchAppListWithSearch",
  async (searchParams) => {
    try {
      const response = await $api.get(`/applications/?` + searchParams);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const setSubDomain = createAsyncThunk(
  "flowSettings/setSubDomain",
  async (dataObj) => {
    try {
      const response = await $api.post(
        "/configuration/flow/create/set_subdomain/",
        dataObj,
      );
      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const fetchDomainsZone = createAsyncThunk(
  "flowSettings/fetchDomainsZone",
  async () => {
    try {
      const { data } = await $api.get("/domains/domains/domains_zone_list/");
      return data;
    } catch (error) {
      const { data } = error.response;

      return data;
    }
  },
);

export const startCreateFlow = createAsyncThunk(
  "application/startCreateFlow",
  async (domainZoneOption) => {
    try {
      let url = "/configuration/flow/create/";
      if (domainZoneOption?.value) {
        url += `?domain_zone=${domainZoneOption.value}`;
      }

      const response = await $api.get(url);
      const { data, status } = response;

      return { data, status };
    } catch (error) {
      if (error && error.response) {
        const { data, status } = error.response;
        return { data, status };
      } else {
        console.error("error.response is undefined");
        return { data: null, status: null };
      }
    }
  },
);

export const fetchPriorities = createAsyncThunk(
  "application/fetchPriorityAppList",
  async () => {
    try {
      const response = await $api.get("/priorities/");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const updatePriorities = createAsyncThunk(
  "application/updatePriorityAppList",
  async ({ data, toast }) => {
    try {
      const response = await $api.put("/priorities/", data);

      toast.success("Приоритет приложений был обновлен");

      return response.data;
    } catch (error) {
      toast.error("Произошла ошибка");
      throw error;
    }
  },
);

export const createRedirectorStep = createAsyncThunk(
  "application/createRedirectorStep",
  async ({ data, onSuccessAction }) => {
    try {
      const response = await $api.post(
        "/configuration/flow/create/set_redirector/",
        data,
      );

      onSuccessAction();

      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

const applicationSlice = createSlice({
  name: "application",
  initialState: {
    appList: [],
    appListLoading: false,

    flowId: null,
    startCreateFlowLoading: false,

    singleApp: {},
    singleAppLoading: false,

    error: null,
    settingsLoading: false,

    createRedirectorStepLoading: false,
    createFlowLoading: false,

    flowList: [],
    totalCount: 0,

    domainsZoneList: [],

    subDomainPending: false,

    popupActive: false,

    priorities: [],
    prioritiesPending: false,
    updatePrioritiesPending: false,
  },
  reducers: {
    setPriorities: (state, action) => {
      state.priorities = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppList.pending, (state) => {
        state.startCreateFlowLoading = true;
        state.appListLoading = true;
        state.appList = [];
      })
      .addCase(fetchAppList.fulfilled, (state, { payload }) => {
        state.appList = payload;
        state.appListLoading = false;
      })
      .addCase(fetchAppList.rejected, (state) => {
        state.appListLoading = false;
        state.appList = [];
      })

      .addCase(fetchAppListWithSearch.pending, (state) => {
        state.appListLoading = true;
        state.appList = [];
      })
      .addCase(fetchAppListWithSearch.fulfilled, (state, { payload }) => {
        state.appList = payload;
        state.appListLoading = false;
      })
      .addCase(fetchAppListWithSearch.rejected, (state) => {
        state.appListLoading = false;
        state.appList = [];
      })

      .addCase(fetchPriorities.pending, (state) => {
        state.priorities = [];
        state.prioritiesPending = true;
      })
      .addCase(fetchPriorities.fulfilled, (state, action) => {
        state.priorities = action.payload.priorities;
        state.prioritiesPending = false;
      })
      .addCase(fetchPriorities.rejected, (state) => {
        state.prioritiesPending = false;
      })

      .addCase(updatePriorities.pending, (state) => {
        state.updatePrioritiesPending = true;
      })
      .addCase(updatePriorities.fulfilled, (state, action) => {
        state.updatePrioritiesPending = false;
        state.priorities = action.payload.priorities;
        console.log("action", action);
      })
      .addCase(updatePriorities.rejected, (state) => {
        state.updatePrioritiesPending = false;
      })

      .addCase(fetchDomainsZone.pending, (state) => {
        state.domainsZoneList = [];
      })
      .addCase(fetchDomainsZone.fulfilled, (state, action) => {
        state.domainsZoneList = action.payload?.domains_zone;
      })
      .addCase(fetchDomainsZone.rejected, (state) => {
        state.domainsZoneList = [];
      })

      /////////////////////////  startCreateFlow  ///////////////////
      .addCase(startCreateFlow.pending, (state) => {
        state.startCreateFlowLoading = true;
        state.createFlowLoading = true;
      })
      .addCase(startCreateFlow.fulfilled, (state, action) => {
        // state.flowId = action.payload.flow.id;
        state.flowId = action.payload?.data?.flow?.id;
        state.startCreateFlowLoading = false;
        state.createFlowLoading = false;
      })
      .addCase(startCreateFlow.rejected, (state) => {
        state.startCreateFlowLoading = false;
        state.createFlowLoading = false;
      })

      .addCase(setSubDomain.pending, (state) => {
        state.subDomainPending = true;
      })
      .addCase(setSubDomain.fulfilled, (state, action) => {
        state.subDomainPending = false;
      })
      .addCase(setSubDomain.rejected, (state) => {
        state.subDomainPending = false;
      })

      /////////////////////  createRedirectorStep  ///////////////////////
      .addCase(createRedirectorStep.pending, (state) => {
        state.createRedirectorStepLoading = true;
      })
      .addCase(createRedirectorStep.fulfilled, (state, action) => {
        state.createRedirectorStepLoading = false;
        state.popupActive = true;
      })
      .addCase(createRedirectorStep.rejected, (state) => {
        state.createRedirectorStepLoading = false;
      });
  },
});

export const { setPriorities } = applicationSlice.actions;

export default applicationSlice.reducer;

export const applicationSelector = (state) => state.application;
