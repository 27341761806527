import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  authSelector,
  resetEmailConfirm,
} from "../../../../redux/slices/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import s from "./emailConfirm.module.scss";
import UnauthorizedPopup from "../../../../components/UiKit/Popups/UnauthorizedPopup/UnauthorizedPopup";
import GlobalSvgSelector from "../../../../components/GlobalSvgSelector/GlobalSvgSelector";
import { notFoundAccount } from "../../../../utils/vars/errorMessages";
import { useTranslation } from "react-i18next";

const EmailConfirm = () => {
  const [isErrorPopupActive, setErrorPopupActive] = useState(false);
  const [isSuccessPopupActive, setSuccessPopupActive] = useState(false);

  const { resetEmailConfirmPending } = useSelector(authSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("incorrectEmailFormat"))
        .required(t("requiredField")),
    }),
    onSubmit: async (form, { setFieldError }) => {
      form.email = form.email.trim();

      const resetEmailConfirmResult = await dispatch(resetEmailConfirm(form));

      const { data, status } = resetEmailConfirmResult?.payload;

      if (status === 204) {
        setSuccessPopupActive(true);
      } else if (data?.email[0] === notFoundAccount) {
        return setFieldError("email", t("noAccForEmail"));
      } else {
        setErrorPopupActive(true);
      }
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  });

  return (
    <form className={s.authFormWrapper} onSubmit={formik.handleSubmit}>
      <UnauthorizedPopup
        active={isSuccessPopupActive}
        description={t("unauthPopupEmailConfirmDesc")}
        title={t("passwordRecovery")}
        confirmBtnText={t("continue")}
        action={() => setSuccessPopupActive(false)}
        email
      />

      <UnauthorizedPopup
        active={isErrorPopupActive}
        description={t("unauthPopupEmailConfirmErrorDesc")}
        title={t("error")}
        confirmBtnText={t("continue")}
        action={() => navigate("/auth/login")}
        support
        error
      />

      <div className={s.welcomeBlock}>
        <Link to="/auth/login">
          <GlobalSvgSelector id="new-logo-icon" />
        </Link>
        <p>{t("passwordRecovery")}</p>
      </div>

      <div className={s.formDescription}>{t("emailConfirmDesc")}</div>

      <div className={s.formFieldsWrapper}>
        <div className={s.fieldWrapper}>
          <p className={s.fieldDescription}>{t("email")}</p>
          <div
            className={`${s.inputWrapper} ${
              formik.touched.email && formik.errors.email ? s.wrapperError : ""
            }`}
          >
            <input
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`${s.input} ${
                formik.touched.email && formik.errors.email ? s.error : ""
              }`}
              type="text"
              placeholder={t("enterEmail")}
            />
          </div>
          {formik.touched.email && formik.errors.email ? (
            <div className={s.errorMessage}>{formik.errors.email}</div>
          ) : null}
        </div>
      </div>
      <div className={s.buttonWrapper}>
        <button
          className={s.enterBtn}
          type="submit"
          disabled={resetEmailConfirmPending}
        >
          {t("confirm")}
        </button>
      </div>
    </form>
  );
};

export default EmailConfirm;
