import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import s from "./mainLayout.module.scss";
import { authSelector, fetchMyUserInfo } from "../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchInitialData } from "../../api/helpers/fetchGlobalData";
import {
  globalDataSelector,
  setExitPopup,
  setNotifyPopup,
} from "../../redux/slices/globalDataSlice";
import { logoutHandler } from "../../api/helpers/handlers";
import Footer from "../../components/partials/Footer/Footer";
import NewTopUpBalancePopup from "../../components/UiKit/Popups/NewTopUpBalancePopup/NewTopUpBalancePopup";
import GlobalSvgSelector from "../../components/GlobalSvgSelector/GlobalSvgSelector";
import NewConfirmPopup from "../../components/UiKit/Popups/NewConfirmPopup/NewConfirmPopup";
import Sidebar from "../../components/partials/Sidebar/Sidebar";
import NotifyPopup from "../../components/UiKit/Popups/NotifyPopup/NotifyPopup";
import { isNotified } from "../../storage/storage";
import {useTranslation} from "react-i18next";

const MainLayout = () => {
  const dispatch = useDispatch();
  const { isPartner } = useSelector(authSelector);
  const { isCollapsedSidebar, exitPopup, isAdaptiveMenuActive } =
    useSelector(globalDataSelector);

  const {t} = useTranslation();

  useEffect(() => {
    dispatch(fetchMyUserInfo());
  }, [dispatch]);

  useEffect(() => {
    if (isPartner === false) {
      fetchInitialData(dispatch);
    }
  }, [dispatch, isPartner]);

  // useEffect(() => {
  //   if (isNotified !== "true") {
  //     dispatch(setNotifyPopup(true));
  //   }
  // }, [dispatch]);

  return (
    <div
      className={`${s.layoutContent} ${isAdaptiveMenuActive ? s.noScroll : ""}`}
    >
      <Sidebar />
      <div
        className={`${s.container} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`}
      >
        <div className={s.pageContentWrapper}>
          <NewConfirmPopup
            icon={<GlobalSvgSelector id="exit-popup-icon" />}
            active={exitPopup}
            setActive={(value) => dispatch(setExitPopup(value))}
            action={() => logoutHandler()}
            confirmBtnText={t('logout')}
            title={t('logout')}
            description={t('logout_from_account')}
            forcedClose={false}
          />

          {/*<NotifyPopup />*/}

          <NewTopUpBalancePopup />
          <Outlet />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;
