import React, { useEffect } from "react";
import s from "./users.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { globalDataSelector } from "../../../../../redux/slices/globalDataSlice";
import useCustomSnackbar from "../../../../../hooks/useCustomSnackbar";
import { useSnackbar } from "notistack";
import { Link, useSearchParams } from "react-router-dom";
import useGetSearchParams from "../../../../../hooks/useGetSearchParams";
import useSetSearchParams from "../../../../../hooks/useSetSearchParams";
import { useDebounce } from "../../../../../hooks/useDebounce";
import {
  page,
  search,
} from "../../../../Authorized/Statistics/ClicksStats/ClicksStats";
import { getTableDefaultParams } from "../../../../../api/helpers/scripts";
import {
  INVALID_PAGE_ERROR,
  snackbar_error,
  snackbar_info,
} from "../../../../../utils/vars/staticVariables";
import EmptyList from "../../../../../components/Blocks/EmptyList/EmptyList";
import CustomSkeleton from "../../../../../components/CustomSkeleton/CustomSkeleton";
import MuiTooltip from "../../../../../components/UiKit/MuiTooltip/MuiTooltip";
import GlobalSvgSelector from "../../../../../components/GlobalSvgSelector/GlobalSvgSelector";
import Pagination from "../../../../../components/Pagination/Pagination";
import { params } from "../../../../../utils/vars/params";
import GradientBorder from "../../../../../components/Blocks/GradientBorder/GradientBorder";
import {
  fetchTechnicalUsersList,
  getImpersonateUser,
  managementSelector,
} from "../../../../../redux/slices/managementSlice";
import { useTranslation } from "react-i18next";

const Users = () => {
  const { isCollapsedSidebar } = useSelector(globalDataSelector);

  const {
    impersonateUserPending,
    technicalUsersList,
    technicalUsersListPending,
    technicalUsersCount,
  } = useSelector(managementSelector);

  const dispatch = useDispatch();

  const showSnackbar = useCustomSnackbar();
  const { closeSnackbar } = useSnackbar();

  const [searchParams, setSearchParams] = useSearchParams();

  const { search: searchValue, page: pageValue } = useGetSearchParams();

  const updateSearchParams = useSetSearchParams(setSearchParams);

  const { t } = useTranslation();

  const [name, setName] = useDebounce((value) => {
    if (value) {
      updateSearchParams(search, value);
    } else {
      updateSearchParams(search, value, true);
    }
  }, 600);

  useEffect(() => {
    if (!searchValue) {
      setName("");
    } else {
      setName(searchValue);
    }
  }, [searchParams]);

  const impersonateUser = async (email) => {
    const impersonateUserPendingSnackbar = showSnackbar(
      `Выполняется вход в ${email}`,
      snackbar_info,
      true,
    );

    const impersonateUserResult = await dispatch(getImpersonateUser(email));

    const res = impersonateUserResult?.payload;

    if (res?.status === 200 && res?.data?.access) {
      localStorage.setItem("impersonateUserToken", res?.data?.access);
      window.location.href = "/app-catalog";
    } else if (res?.data?.detail === "Юзер не найден") {
      closeSnackbar(impersonateUserPendingSnackbar);
      showSnackbar(
        `${t("impersonate_user")} ${email} ${t("impersonate_was_not_found")}`,
        snackbar_error,
      );
    } else if (res?.data?.detail === "Этот юзер неактивний") {
      closeSnackbar(impersonateUserPendingSnackbar);
      showSnackbar(t("this_user_is_inactive"), snackbar_error);
    } else {
      closeSnackbar(impersonateUserPendingSnackbar);
      showSnackbar(t("impersonate_error"), snackbar_error);
    }
  };

  return (
    <div className={s.usersTableWrapper}>
      <GradientBorder className={s.gradientBorderCustom} />

      <div className={s.pageInnerContent}>
        <div className={s.innerContentContainer}>
          {technicalUsersCount === 0 && !technicalUsersListPending ? (
            <EmptyList />
          ) : technicalUsersListPending ? (
            <section className={s.body}>
              <table>
                <tr>
                  <th>{t("email")}</th>
                  <th>{t("team")}</th>
                  <th>{t("price_per_install")}</th>
                  <th></th>
                  <th></th>
                </tr>
              </table>
              <div className={s.skeletonWrapper}>
                <CustomSkeleton styles={s.skeletonTable} />
              </div>
            </section>
          ) : (
            <section className={s.body}>
              <table>
                <thead>
                  <tr>
                    <th>{t("email")}</th>
                    <th>{t("team")}</th>
                    <th>{t("price_per_install")}</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {technicalUsersList?.map((user) => {
                    const { email, install_price_bonus, offer, id } = user;

                    return (
                      <tr key={email}>
                        <td>{email}</td>
                        <td>{offer}</td>
                        <td>{install_price_bonus}</td>
                        <td className={s.enterBtnTd}>
                          <button
                            onClick={() => impersonateUser(email)}
                            className={s.primaryBtn}
                            disabled={impersonateUserPending}
                          >
                            {t("enterAcc")}
                          </button>
                        </td>

                        <td>
                          <div className={s.actionsWrapper}>
                            <div className={s.actionsContainer}>
                              <div className={s.actions}>
                                <Link
                                  className={`${s.manageTableFillBtn} ${s.statsBtn}`}
                                  to={`/management/edit-user/${id}`}
                                >
                                  <MuiTooltip title={t("edit_option")}>
                                    <GlobalSvgSelector id="edit-icon" />
                                  </MuiTooltip>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </section>
          )}
        </div>
      </div>

      <div
        className={`${s.paginationContainer} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`}
      >
        <div className={s.newRecordsCount}>
          {t("total")}:{" "}
          {!technicalUsersListPending && <span>{technicalUsersCount}</span>}
        </div>
        {technicalUsersCount > 25 && (
          <Pagination
            currentPage={parseInt(pageValue) || 1}
            setCurrentPage={(page) => {
              updateSearchParams(params.page, page);
            }}
            total={technicalUsersCount}
            limit={25}
          />
        )}
      </div>
    </div>
  );
};

export default Users;
