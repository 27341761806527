import {
  getIsAllowedStatisticsAccess,
  getIsStaff,
} from "../../storage/storage";

export const wordPressOptions = [
  { value: "music", label: "Музыка" },
  { value: "beauty", label: "Красота" },
  { value: "travel", label: "Путешествия" },
  { value: "food", label: "Рецепты" },
  { value: "science", label: "Наука" },
  { value: "gaming", label: "Игры" },
  { value: "energy", label: "Энергетика" },
  { value: "news", label: "Новости" },
  { value: "sport", label: "Спорт" },
  { value: "tech", label: "Технологии" },
  { value: "finance", label: "Финансы" },
  { value: "politics", label: "Политика" },
  { value: "business", label: "Бизнес" },
  { value: "economics", label: "Экономика" },
  { value: "entertainment", label: "Развлечения" },
];

export const prelandingStatusList = [
  { value: true, label: "display" },
  { value: false, label: "not_display" },
];

export const whitePageTypeOptions = [
  { value: "wp", label: "WordPress" },
  { value: "gp", label: "GooglePlay" },
  { value: "ap", label: "AppStore" },
];

export const cloacaTypesOptions = [{ value: "fb_lite", label: "Facebook" }];

export const cloacaModeOptions = [
  { value: "Filter", label: "filter_cloaca_mode", isDisabled: false },
  { value: "Review", label: "moderation_cloaca_mode", isDisabled: false },
  { value: "Money", label: "money_cloaca_mode", isDisabled: false },
];

export const snackbar_error = "error";
export const snackbar_default = "default";
export const snackbar_success = "success";
export const snackbar_warning = "warning";
export const snackbar_info = "info";

export const prelandTypeList = [{ label: "wheel", name: "Колесо" }];

export const prelandingTypesList = [{ value: 1, label: "wheel_type" }];

export const FLOW_STATUS_FILTER = "Включена";
export const FLOW_STATUS_REVIEWS = "Режим модерации";
export const FLOW_STATUS_MONEY = "Выключена";

export const ABORTED_ERROR = "Aborted";
export const REJECTED_ERROR = "Rejected";
export const INVALID_PAGE_ERROR = "Invalid page.";

export const PURPOSE_REFILL = "refill";
export const PURPOSE_WRITE_OFF = "write_off";

export const newSidebarList = [
  {
    title: "menuSidebarTitle",
    pages: [
      {
        iconId: "app-catalog",
        text: "Приложения",
        path: "/app-catalog",
        name: "applications",
      },
      {
        iconId: "my-flows",
        text: "Потоки",
        path: "/my-flows",
        name: "flows",
      },
      {
        iconId: "redirectors",
        text: "Редиректор",
        path: "/all-redirectors",
        name: "redirectors",
      },
      {
        iconId: "facebook",
        text: "Pixel API",
        path: "/pixel-api",
        name: "pixelApi",
      },
      {
        iconId: "transactions",
        text: "Транзакции",
        path: "/transactions",
        name: "transactions",
      },
      getIsStaff() || getIsAllowedStatisticsAccess()
        ? {
            text: "Балансы",
            iconId: "balances",
            path: "/balances",
            name: "balances",
          }
        : null,
      {
        text: "Прелендинги",
        iconId: "prelandings",
        path: "/prelandings",
        name: "prelandings",
      },
    ].filter((item) => item !== null),
  },
  getIsStaff() || getIsAllowedStatisticsAccess()
    ? {
        title: "supportSidebarTitle",
        pages: [
          {
            text: "Управление",
            iconId: "management-icon",
            path: "/management",
            name: "management",
          },
        ],
      }
    : null,
  {
    title: "statisticsSidebarTitle",
    pages: [
      {
        text: "Общая",
        iconId: "common-stats",
        path: "/stats/common",
        name: "common",
      },
      {
        text: "Клики",
        iconId: "clicks-stats",
        path: "/stats/clicks",
        name: "clicks",
      },
      {
        text: "Клоака",
        iconId: "cloak-stats",
        path: "/stats/cloaca",
        name: "cloaca",
      },
      {
        text: "Логи постбеков",
        iconId: "postback-status-icon",
        path: "/stats/postback-logs",
        name: "postbackLogs",
      },
      getIsStaff() || getIsAllowedStatisticsAccess()
        ? {
            iconId: "geo-app-stats",
            text: "GEO + APP",
            path: "/stats/geo+app",
            name: "geo_plus_app",
          }
        : null,
      getIsStaff() || getIsAllowedStatisticsAccess()
        ? {
            iconId: "users-stats",
            text: "Пользователи",
            path: "/stats/users",
            name: "users",
          }
        : null,
    ].filter((item) => item !== null),
  },
  {
    title: "otherSidebarTitle",
    pages: [
      {
        iconId: "documentation",
        text: "Документация",
        path: "/documentation",
        name: "documentation",
      },
    ],
  },
].filter((item) => item !== null);

export const colorList = [
  "#5d87ff",
  "#8FF89A",
  "#FF5DC8",
  "#D98B60",
  "#FF3A3A",
  "#BCBCBC",
  "#958c65",
  "#b2dad7",
  "#2056a4",
  "#e7fdc7",
  "#369e6d",
  "#fa4696",
  "#b16969",
  "#2389b9",
  "#f70b2e",
  "#21d8c9",
  "#2703eb",
  "#d61616",
  "#86f463",
  "#1749aa",
  "#7ee056",
  "#7e54c9",
  "#21d434",
  "#9e45d2",
  "#8993b9",
  "#167755",
  "#f82dbd",
  "#469085",
  "#640187",
  "#4cf1b4",
  "#b07d54",
  "#266533",
  "#403dd8",
  "#548912",
  "#4622e8",
  "#88642c",
  "#23b2b5",
  "#3298d1",
  "#9c4be0",
  "#796e13",
  "#36dfb5",
  "#efab40",
  "#b3285c",
  "#415241",
  "#c72ce9",
  "#4c771d",
  "#ccdc79",
  "#23f789",
  "#ba1e05",
  "#dc6ce1",
  "#9ad9a3",
  "#357657",
  "#6f6cbc",
  "#6e190b",
  "#1aa022",
  "#247aca",
  "#569b1e",
  "#c3198c",
  "#f68e75",
  "#6adaac",
  "#5a633e",
  "#faf200",
  "#e0f6e7",
  "#2acdb4",
  "#940f60",
  "#e5249e",
  "#94fe5e",
  "#e51686",
  "#530ee3",
  "#bac3c1",
  "#7a79d9",
  "#6e9946",
  "#ee14a9",
  "#57ea7d",
  "#3f7e04",
  "#7f2169",
  "#91d98d",
  "#e9f6af",
  "#908bd5",
  "#780385",
  "#f3d3bd",
  "#f4f234",
  "#427ed0",
  "#2056a4",
  "#e6eb04",
  "#f0d4b1",
  "#81490a",
  "#50f1cb",
  "#59e523",
  "#f4a6be",
  "#f3a517",
];
