import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFlowById,
  flowSettingsSelector,
  setFlowObj,
  setFlowPrelanding,
  setFlowRedirect,
  setWhitePageSettings,
} from "../../../redux/slices/flowSettingsSlice";
import s from "./flowSettings.module.scss";
import {
  globalDataSelector,
  setAdaptiveMenu,
} from "../../../redux/slices/globalDataSlice";
import { setTopUpBalanceActive } from "../../../redux/slices/financeSlice";
import GlobalSvgSelector from "../../../components/GlobalSvgSelector/GlobalSvgSelector";
import WhitePage from "./Tabs/WhitePage/WhitePage";
import { fetchFlowSettingsData } from "../../../api/helpers/fetchFilters";
import { useFormik } from "formik";
import {
  cloacaModeOptions,
  prelandingStatusList,
  prelandingTypesList,
  snackbar_error,
  snackbar_info,
  snackbar_success,
  snackbar_warning,
  whitePageTypeOptions,
  wordPressOptions,
} from "../../../utils/vars/staticVariables";
import * as Yup from "yup";
import { setNote } from "../../../redux/slices/flowSlice";
import useCustomSnackbar from "../../../hooks/useCustomSnackbar";
import Prelanding from "./Tabs/Prelanding/Prelanding";
import {
  chosenSamePrelanding,
  noIndexHtml,
  noIndexHtmlFormikError, noLinkInIndexHtml,
  notRedirectorOwnerError,
  prelandingFileCyrillicError,
} from "../../../utils/vars/errorMessages";
import { turnedOffPrelandFromCloacka } from "../../../utils/vars/messages";
import {
  createPrelanding,
  prelandingSelector,
  resetStylesList,
  setAllPrelandingsList,
  setWheelStylesList,
} from "../../../redux/slices/prelandingSlice";
import Redirector from "./Tabs/Redirector/Redirector";
import {
  failed_to_upload_prelanding,
  file_cyrillic_error,
  not_found_index_html,
  turnedOffPrelandFromCloacaNotif,
} from "../../../utils/vars/notificationsMessages";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "../../../components/partials/LanguageDropdown/LanguageDropdown";
import {getIsAllowedStatisticsAccess, getIsStaff} from "../../../storage/storage";
import {managementSelector} from "../../../redux/slices/managementSlice";

const FlowSettings = () => {
  const [currentTab, setCurrentTab] = useState("whitePage");
  const [prelandContent, setPrelandContent] = useState("choose");
  const [isSwitchTabAllowed, setSwitchTabAllowed] = useState(false);
  const [uploadPrelandingPopupIsActive, setUploadPrelandingPopupIsActive] =
    useState(false);

  const underlineRef = useRef(null);

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const { isCollapsedSidebar, isAdaptiveMenuActive } =
    useSelector(globalDataSelector);

  const { flowObj, fetchFlowByIdPending, createPrelandingFormValues } =
    useSelector(flowSettingsSelector);
  const { standardClickPriceValue } = useSelector(managementSelector);


  useEffect(() => {
    if (flowObj?.settings) {
      setSwitchTabAllowed(true);
    }
  }, [flowObj]);

  const { wheelStylesList, allPrelandingsList } =
    useSelector(prelandingSelector);

  const showSnackbar = useCustomSnackbar();

  useEffect(() => {
    const activeBtn = document.querySelector(`.${s.active}`);
    if (activeBtn && underlineRef.current) {
      const { left, width } = activeBtn.getBoundingClientRect();
      const parentLeft = activeBtn.parentElement.getBoundingClientRect().left;
      const scrollLeft = activeBtn.parentElement.scrollLeft; // Додаємо врахування скролу

      underlineRef.current.style.width = `${width}px`;
      underlineRef.current.style.transform = `translateX(${left - parentLeft + scrollLeft}px)`;
    }
  }, [currentTab, i18n.language]);

  useEffect(() => {
    if (Number.isInteger(Number(id))) {
      dispatch(fetchFlowById(id));
    } else {
      navigate("/my-flows");
    }
  }, [dispatch, id, navigate]);

  useEffect(() => {
    fetchFlowSettingsData(dispatch);
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setFlowObj(null));
    };
  }, [dispatch]);

  const {
    allowed_ips = [],
    geo = [],
    url_for_whitepage_app = "",
    url = "",
    status = cloacaModeOptions[0],
    type_of_cloaca = "",
  } = flowObj?.settings || {};

  const choosePrelandingFormik = useFormik({
    initialValues: {
      prelanding_id: flowObj?.prelanding?.id || null,
      flow_id: flowObj?.id,
    },
    enableReinitialize: true,
    onSubmit: async (form) => {
      const setFlowPrelandingResult = await dispatch(setFlowPrelanding(form));

      if (setFlowPrelandingResult?.payload?.status === 200) {
        if (
          setFlowPrelandingResult?.payload?.data?.details ===
          turnedOffPrelandFromCloacka
        ) {
          showSnackbar(t(turnedOffPrelandFromCloacaNotif), snackbar_success);
          setCurrentTab("redirect");
        } else {
          showSnackbar(t("set_flow_prelanding"), snackbar_success);
          setCurrentTab("redirect");
        }
      } else {
        if (
          setFlowPrelandingResult?.payload?.data?.details ===
          chosenSamePrelanding
        ) {
          showSnackbar(chosenSamePrelanding, snackbar_error);
        } else {
          showSnackbar(t("failed_to_set_flow_prelanding"), snackbar_error);
        }
      }
    },
    validateOnMount: true,
    validateOnBlur: true,
  });

  const initialCreatePrelandingValues = useMemo(
    () => ({
      status: prelandingStatusList[0].value,
      name: "",
      num_of_revolutions: 1,
      prelanding_type: prelandingTypesList[0].value,
      header_text: "Welcome",
      rotation_button_text: "Spin",
      respin_button_text: "Spin",
      style: wheelStylesList[0]?.id,
      sectors: new Array(8).fill().map((_, index) => ({
        name: `Сектор ${index}`,
        value: "",
        is_win: index <= 0,
      })),
      bonus_button_text: "ОК",
      popup_win_text: "You get {bonus}",
      winning_button_text: "Get",
      logo: null,
    }),
    [wheelStylesList],
  );

  const resetCreatePrelandingForm = () => {
    createPrelandingFormik.resetForm({
      values: initialCreatePrelandingValues,
    });
  };

  const createPrelandingFormik = useFormik({
    initialValues: initialCreatePrelandingValues,
    // enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required(t("requiredField")),
      rotation_button_text: Yup.string().required(t("requiredField")),
      respin_button_text: Yup.string().required(t("requiredField")),
      sectors: Yup.array().of(
        Yup.object().shape({
          value: Yup.string().required(t("fill_sector_value")),
        }),
      ),
      bonus_button_text: Yup.string().required(t("requiredField")),
      winning_button_text: Yup.string().required(t("requiredField")),
      style: Yup.string().required(t("requiredField")),
    }),
    onSubmit: async (data) => {
      const createPrelandingResult = await dispatch(createPrelanding(data));
      if (createPrelandingResult?.payload?.status === 201) {
        const updatedPrelandingsList = [
          createPrelandingResult?.payload?.data,
          ...allPrelandingsList,
        ];

        dispatch(setAllPrelandingsList(updatedPrelandingsList));
        choosePrelandingFormik.setFieldValue(
          "prelanding_id",
          createPrelandingResult?.payload?.data?.id,
        );

        resetCreatePrelandingForm();

        showSnackbar(
          `${t("prelanding")} ${createPrelandingResult?.payload?.data?.name} ${t("is_created")}`,
          snackbar_success,
        );
        showSnackbar(t("set_created_prelanding"), snackbar_info);
        setPrelandContent("choose");
      } else {
        showSnackbar(t("failed_to_create_prelanding"), snackbar_error);
      }
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const setFlowRedirectorFormik = useFormik({
    initialValues: {
      redirectors: flowObj?.redirector || [],
    },
    enableReinitialize: true,
    validationSchema: Yup.object({}),
    onSubmit: async (data) => {
      if (data.redirectors.length < 1) {
        return showSnackbar(t("choose_min_1_redirect"), snackbar_error);
      }

      const dataObj = {
        flow_id: flowObj?.id,
        redirector_id: data.redirectors.map((item) => item.id),
      };

      const setFlowRedirectResult = await dispatch(setFlowRedirect(dataObj));

      if (setFlowRedirectResult?.payload?.status === 201) {
        showSnackbar(t("flow_redirect_is_saved"), snackbar_success);

        navigate("/my-flows");
      } else if (
        setFlowRedirectResult?.payload?.data?.details?.includes(
          notRedirectorOwnerError,
        )
      ) {
        showSnackbar(
          setFlowRedirectResult?.payload?.data?.details,
          snackbar_error,
        );
      } else {
        showSnackbar(t("failed_to_set_redirect"), snackbar_error);
      }
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const uploadPrelandingFormik = useFormik({
    initialValues: {
      prelanding_file: null,
      prelanding_type: 4,
      name: "",
    },
    // enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required(t("requiredField")),
      prelanding_file: Yup.string().required(t("upload_zip_archive")),
    }),
    onSubmit: async (data, { setFieldError, validateField, setFieldValue }) => {
      const formData = new FormData();
      formData.append("prelanding_file", data.prelanding_file);
      formData.append("prelanding_type", data.prelanding_type);
      formData.append("name", data.name);

      const createPrelandingResult = await dispatch(createPrelanding(formData));

      if (createPrelandingResult?.payload?.status === 201) {
        const updatedPrelandingsList = [
          createPrelandingResult?.payload?.data,
          ...allPrelandingsList,
        ];

        dispatch(setAllPrelandingsList(updatedPrelandingsList));
        choosePrelandingFormik.setFieldValue(
          "prelanding_id",
          createPrelandingResult?.payload?.data?.id,
        );

        showSnackbar(
          `${t("prelanding")} ${createPrelandingResult?.payload?.data?.name} ${t("was_uploaded")}`,
          snackbar_success,
        );
        showSnackbar(t("set_uploaded_prelanding"), snackbar_info);
        setPrelandContent("choose");
        setUploadPrelandingPopupIsActive(false);
        uploadPrelandingFormik.resetForm();
      } else if (
        createPrelandingResult?.payload?.data?.detail === noIndexHtml
      ) {
        return setFieldError("prelanding_file", t(not_found_index_html));
      } else if (
        createPrelandingResult?.payload?.data?.detail === noLinkInIndexHtml
      ) {
        return setFieldError("prelanding_file", t('no_link_in_index_html'));
      } else if (
        createPrelandingResult?.payload?.data?.detail ===
        prelandingFileCyrillicError
      ) {
        return setFieldError("prelanding_file", t(file_cyrillic_error));
      } else {
        showSnackbar(t(failed_to_upload_prelanding), snackbar_error);
      }
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const whitePageFormik = useFormik({
    initialValues: {
      flow_id: flowObj?.id,
      cloack: "fb_lite", // Захардкодженый тип клоаки
      url: url, // Ссылка на оффер
      geo: geo.map((item) => ({ label: item.name, value: item.id })),
      url_for_whitepage_app: url_for_whitepage_app,
      notes: flowObj?.notes,
      allowed_ips: allowed_ips.map((item) => ({ value: item, label: item })), // Айпи вайт пейджа
      type_of_cloaca: type_of_cloaca
        ? whitePageTypeOptions.find((item) => item.value === type_of_cloaca)
        : whitePageTypeOptions[0], // Тип вайт пейджа
      status: (() => {
        const foundOption = cloacaModeOptions.find(
          (item) => item.value === status,
        );

        if (foundOption) {
          return { ...foundOption, label: t(foundOption.label) };
        }

        // Якщо нічого не знайдено, використовуємо перший елемент масиву
        return {
          ...cloacaModeOptions[0],
          label: t(cloacaModeOptions[0].label),
        };
      })(),
      appStoreUrl: "",
      googlePlayUrl: "",
      wordPressOption: {
        ...wordPressOptions[0],
        label: t(wordPressOptions[0].value), // Заміна label на перекладене значення value
      },
      // wordPressOption: wordPressOptions[0],
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      geo: Yup.array().min(1, t("requiredField")),

      url: Yup.string()
        .trim()
        .matches(/^https:\/\/.*/, t("incorrect_link_format"))
        .matches(/\{tds_clickid\}/, t("link_must_contain_tds_clickid"))
        .matches(/^[^\s]+$/, t("validation_no_space_between_characters"))
        .required(t("requiredField")),

      notes: Yup.string()
        .trim()
        .test("is-not-empty", "Notes cannot be empty", function (value) {
          return value.trim() !== "";
        })
        .required(t("requiredField")),

      googlePlayUrl: Yup.string().when("type_of_cloaca", {
        is: (type_of_cloaca) => type_of_cloaca?.value === "gp",
        then: () =>
          Yup.string()
            .matches(
              /^https:\/\/play\.google\.com\//,
              `${t("link_must_contain")} https://play.google.com/`,
            )
            .required(t("requiredField")),
        otherwise: () => Yup.string().nullable(),
      }),

      appStoreUrl: Yup.string().when("type_of_cloaca", {
        is: (type_of_cloaca) => type_of_cloaca?.value === "ap",
        then: () =>
          Yup.string()
            .matches(
              /^https:\/\/apps\.apple\.com\//,
              `${t("link_must_contain")} https://apps.apple.com/`,
            )
            .required(t("requiredField")),
        otherwise: () => Yup.string().nullable(),
      }),
    }),
    onSubmit: async (form) => {
      const {
        flow_id,
        cloack,
        url,
        geo,
        notes,
        allowed_ips,
        type_of_cloaca,
        status,
        appStoreUrl,
        googlePlayUrl,
        wordPressOption,
      } = form;

      const noteData = {
        flow_id: flow_id,
        notes: notes?.trim(),
      };

      dispatch(setNote(noteData));

      const dataObj = {
        flow_id,
        cloack,
        url: url?.trim(),
        geo: geo.map((item) => item.value),
        allowed_ips: allowed_ips.map((item) => item.value),
        status: status.value,
        type_of_cloaca: type_of_cloaca.value,
      };

      if (type_of_cloaca.value === "ap") {
        dataObj.url_for_whitepage_app = appStoreUrl;
      }

      if (type_of_cloaca.value === "gp") {
        dataObj.url_for_whitepage_app = googlePlayUrl;
      }

      if (type_of_cloaca.value === "wp") {
        dataObj.url_for_whitepage_app = wordPressOption.value;
      }

      const setWhitePageSettingsResult = await dispatch(
        setWhitePageSettings(dataObj),
      );

      if (setWhitePageSettingsResult?.payload?.status === 200) {
        setSwitchTabAllowed(true);
        setCurrentTab("preland");
        showSnackbar(t("flow_settings_are_saved"), snackbar_success);
      } else {
        showSnackbar(t("failed_to_save_settings"), snackbar_error);
      }
    },
    validateOnMount: true,
    validateOnBlur: true,
  });

  useEffect(() => {
    return () => {
      dispatch(setAllPrelandingsList([]));
      dispatch(setWheelStylesList([]));
    };
  }, [dispatch]);

  useEffect(() => {
    const currentTypeOfCloaca = flowObj?.settings?.type_of_cloaca;
    const urlForWhitePageApp = flowObj?.settings?.url_for_whitepage_app;

    if (currentTypeOfCloaca === "ap") {
      whitePageFormik.setFieldValue("appStoreUrl", urlForWhitePageApp);
    }

    if (currentTypeOfCloaca === "gp") {
      whitePageFormik.setFieldValue("googlePlayUrl", urlForWhitePageApp);
    }
    if (currentTypeOfCloaca === "wp") {
      let selectedItem = wordPressOptions.find(
        (item) => item.value === urlForWhitePageApp,
      );

      if (selectedItem) {
        selectedItem = { ...selectedItem, label: t(selectedItem.value) };
      } else {
        selectedItem = { ...selectedItem, label: t(selectedItem.value) };
      }

      whitePageFormik.setFieldValue("wordPressOption", selectedItem);
    }
  }, [flowObj]);

  useEffect(() => {
    if (wheelStylesList.length > 0) {
      createPrelandingFormik.setFieldValue("style", wheelStylesList[0]?.id);
    }
  }, [wheelStylesList]);

  return (
    <div
      className={`${s.flowSettingsWrapper} ${
        isCollapsedSidebar === "true" ? s.collapsedSidebar : ""
      }`}
    >
      <div className={s.pageHeaderWrapper}>
        <div className={s.pageHeaderContainer}>
          <div className={s.staticElements}>
            <div className={s.leftSideWrapper}>
              <button
                className={s.walletBtn}
                onClick={() => {
                  dispatch(setAdaptiveMenu(false));
                  dispatch(setTopUpBalanceActive(true));
                }}
              >
                <GlobalSvgSelector id="wallet" />
              </button>
              <Link className={s.walletBtn} to="/account">
                <GlobalSvgSelector id="account-gear-icon" />
              </Link>
              {(getIsStaff() || getIsAllowedStatisticsAccess()) && (
                  <Link to='/management' className={s.standardInstallPrice}>
                    <GlobalSvgSelector id='standard-install-price-icon'/>
                    <span>$ {standardClickPriceValue || "----" }</span>
                  </Link>
              )}
              <LanguageDropdown />
            </div>

            <button
              type="button"
              className={s.menuBtn}
              onClick={() => dispatch(setAdaptiveMenu(!isAdaptiveMenuActive))}
            >
              <GlobalSvgSelector id="burger-menu" />
            </button>
          </div>
        </div>
      </div>
      {/*<div className={s.pageContent}>*/}
      <div className={s.pageContent}>
        <div className={s.pageMarking}>
          <Link to="/my-flows" className={s.link}>
            {t("flows")}
          </Link>
          <GlobalSvgSelector id="arrow-right" />
          {/*<span>Редактирование</span>*/}
          {/*<GlobalSvgSelector id="arrow-right" />*/}
          <span>{fetchFlowByIdPending ? "..." : flowObj?.domain?.address}</span>
        </div>
        <div className={s.switchPageContentContainer}>
          <div className={s.buttonsContainer}>
            <button
              onClick={() => setCurrentTab("whitePage")}
              className={`${s.pageBtn} ${currentTab === "whitePage" ? s.active : ""}`}
            >
              <GlobalSvgSelector id="os-select-icon" />
              {t("settings")}
            </button>

            <button
              onClick={() => {
                if (flowObj) {
                  if (isSwitchTabAllowed) {
                    setCurrentTab("preland");
                  } else {
                    showSnackbar(t("set_white_page"), snackbar_warning);
                  }
                }
              }}
              className={`${s.pageBtn} ${currentTab === "preland" ? s.active : ""} 
              ${!isSwitchTabAllowed ? s.disabled : ""}`}
            >
              <GlobalSvgSelector id="preland-indicator-icon" />
              {t("prelanding")}
            </button>

            <button
              onClick={() => {
                if (flowObj) {
                  if (isSwitchTabAllowed) {
                    setCurrentTab("redirect");
                  } else {
                    showSnackbar(t("set_white_page"), snackbar_warning);
                  }
                }
              }}
              className={`${s.pageBtn} ${currentTab === "redirect" ? s.active : ""} 
              ${!isSwitchTabAllowed ? s.disabled : ""}`}
            >
              <GlobalSvgSelector id="redirect-indicator-icon" />
              {t("redirector")}
            </button>
            <div className={s.underline} ref={underlineRef}></div>
          </div>
        </div>
        {/*<div className={s.pageInnerContent}>*/}
        {currentTab === "whitePage" && <WhitePage formik={whitePageFormik} />}
        {currentTab === "preland" && (
          <Prelanding
            content={prelandContent}
            setContent={setPrelandContent}
            resetCreatePrelandingForm={resetCreatePrelandingForm}
            choosePrelandingFormik={choosePrelandingFormik}
            createPrelandingFormik={createPrelandingFormik}
            uploadPrelandingFormik={uploadPrelandingFormik}
            uploadPrelandingPopupIsActive={uploadPrelandingPopupIsActive}
            setUploadPrelandingPopupIsActive={setUploadPrelandingPopupIsActive}
          />
        )}
        {currentTab === "redirect" && (
          <Redirector formik={setFlowRedirectorFormik} />
        )}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default FlowSettings;
