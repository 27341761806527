import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import {
  checkAllTokens,
  deletePixelApiItem,
  fetchPixelApi,
} from "../../../redux/slices/pixelApiSlice";
import {
  params,
  pixelApiHandleSupportOptions,
  pixelApiStatusOptions,
} from "../../../utils/vars/params";
import s from "./pixelApi.module.scss";
import MultiSelect from "../../../components/Blocks/Selects/MultiSelect/MultiSelect";

import EmptyList from "../../../components/Blocks/EmptyList/EmptyList";
import CustomSkeleton from "../../../components/CustomSkeleton/CustomSkeleton";
import Pagination from "../../../components/Pagination/Pagination";
import PixelApiTd from "./PixelApiTd/PixelApiTd";
import {
  globalDataSelector,
  setAdaptiveMenu,
} from "../../../redux/slices/globalDataSlice";
import { setTopUpBalanceActive } from "../../../redux/slices/financeSlice";
import GlobalSvgSelector from "../../../components/GlobalSvgSelector/GlobalSvgSelector";
import GradientSearch from "../../../components/partials/GradientSearch/GradientSearch";
import useGetSearchParams from "../../../hooks/useGetSearchParams";
import useSetSearchParams from "../../../hooks/useSetSearchParams";
import { useDebounce } from "../../../hooks/useDebounce";
import {
  page,
  search,
  status,
  is_support_handled,
} from "../Statistics/ClicksStats/ClicksStats";
import { getTableDefaultParams } from "../../../api/helpers/scripts";
import {
  cloacaModeOptions,
  INVALID_PAGE_ERROR,
  prelandingStatusList,
  snackbar_error,
  snackbar_info,
  snackbar_success,
} from "../../../utils/vars/staticVariables";
import useCustomSnackbar from "../../../hooks/useCustomSnackbar";
import { useSnackbar } from "notistack";
import CreatePixelApi from "./Popups/CreatePixelApi/CreatePixelApi";
import { hasNonEmptyParams } from "../../../utils/helpers/hasNoneEmptyParams";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "../../../components/partials/LanguageDropdown/LanguageDropdown";
import {
  getIsAllowedStatisticsAccess,
  getIsStaff,
} from "../../../storage/storage";
import {managementSelector} from "../../../redux/slices/managementSlice";

const PixelApi = () => {
  const [isActiveCreatePixelApi, setCreatePixelApi] = useState(false);
  const { pixelApiList, pixelApiListPending, totalCount } = useSelector(
    (state) => state.pixelApi,
  );
  const { isCollapsedSidebar, isAdaptiveMenuActive } =
    useSelector(globalDataSelector);
  const { standardClickPriceValue } = useSelector(managementSelector);

  const dispatch = useDispatch();

  const showSnackbar = useCustomSnackbar();
  const { closeSnackbar } = useSnackbar();

  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const {
    page: pageValue,
    search: searchValue,
    is_support_handled: isSupportHandledValue,
    status: statusValue,
  } = useGetSearchParams();

  const updateSearchParams = useSetSearchParams(setSearchParams);

  const selectedStatus = useMemo(() => {
    return (
      pixelApiStatusOptions.find((item) => item.value === statusValue) || null
    );
  }, [statusValue]);

  const selectedSupportHandle = useMemo(() => {
    return (
      pixelApiHandleSupportOptions.find(
        (item) => item.value === isSupportHandledValue,
      ) || null
    );
  }, [isSupportHandledValue]);

  useEffect(() => {
    if (selectedStatus === null) {
      updateSearchParams(status, "", true);
    }
    if (selectedSupportHandle === null) {
      updateSearchParams(is_support_handled, "", true);
    }
  }, []);

  const [name, setName] = useDebounce((value) => {
    if (value) {
      updateSearchParams(search, value);
    } else {
      updateSearchParams(search, value, true);
    }
  }, 600);

  useEffect(() => {
    if (!searchValue) {
      setName("");
    } else {
      setName(searchValue);
    }
  }, [searchParams]);

  const fetchPixelApiData = useCallback(() => {
    const promise = dispatch(
      fetchPixelApi(
        getTableDefaultParams({
          searchParams,
          defaultPageSize: 25,
        }),
      ),
    );

    promise.unwrap().catch((err) => {
      if (err?.message === INVALID_PAGE_ERROR) {
        updateSearchParams(page, 1);
      }
    });

    return promise;
  }, [dispatch, searchParams, updateSearchParams]);

  useEffect(() => {
    const promise = fetchPixelApiData();

    return () => {
      promise.abort();
    };
  }, [dispatch, searchParams]);

  const onDeleteHandler = useCallback(
    async (id) => {
      const pendingDeleteSnackbar = showSnackbar(
        t("notif_delete_flow"),
        snackbar_info,
        true,
      );

      const pixelDeleteResult = await dispatch(deletePixelApiItem(id));

      if (pixelDeleteResult?.payload?.status === 204) {
        fetchPixelApiData();
        dispatch(checkAllTokens());
        closeSnackbar(pendingDeleteSnackbar);
        showSnackbar(t("pixel_api_is_deleted"), snackbar_success);
      } else {
        closeSnackbar(pendingDeleteSnackbar);
        showSnackbar(t("failed_to_remove_pixel_api"), snackbar_error);
      }
    },
    [closeSnackbar, dispatch, fetchPixelApiData, showSnackbar],
  );

  const clearSearchParams = () => {
    setSearchParams(new URLSearchParams());
  };

  const returnSelectedStatus = (() => {
    const foundOption = pixelApiStatusOptions.find(
      ({ value }) => value === selectedStatus?.value,
    );

    if (foundOption) {
      return { ...foundOption, label: t(foundOption.label) };
    }

    return null;
  })();

  const returnSelectedSupportHandle = (() => {
    const foundOption = pixelApiHandleSupportOptions.find(
      ({ value }) => value === selectedSupportHandle?.value,
    );

    if (foundOption) {
      return { ...foundOption, label: t(foundOption.label) };
    }

    return null;
  })();

  return (
    <div
      className={`${s.pixelWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
    >
      <CreatePixelApi
        active={isActiveCreatePixelApi}
        setActive={setCreatePixelApi}
        fetchPixelApiList={() => fetchPixelApiData()}
      />
      <div className={s.pageHeaderWrapper}>
        <div className={s.pageHeaderContainer}>
          <div className={s.staticElements}>
            <div className={s.leftSideWrapper}>
              <button
                className={s.walletBtn}
                onClick={() => {
                  dispatch(setAdaptiveMenu(false));
                  dispatch(setTopUpBalanceActive(true));
                }}
              >
                <GlobalSvgSelector id="wallet" />
              </button>
              <Link className={s.walletBtn} to="/account">
                <GlobalSvgSelector id="account-gear-icon" />
              </Link>
              {(getIsStaff() || getIsAllowedStatisticsAccess()) && (
                  <Link to='/management' className={s.standardInstallPrice}>
                    <GlobalSvgSelector id='standard-install-price-icon'/>
                    <span>$ {standardClickPriceValue || "----" }</span>
                  </Link>
              )}
              <LanguageDropdown />
            </div>

            <button
              className={s.menuBtn}
              onClick={() => dispatch(setAdaptiveMenu(!isAdaptiveMenuActive))}
            >
              <GlobalSvgSelector id="burger-menu" />
            </button>
          </div>

          <div className={s.changingElements}>
            <GradientSearch value={name} setValue={setName} />

            <button
              className={s.primaryBtn}
              onClick={() => setCreatePixelApi(true)}
            >
              <GlobalSvgSelector id="plus-button" />
              {t("create")}
            </button>
          </div>
        </div>
      </div>

      <div className={s.pageContent}>
        <div className={s.filtersWrapper}>
          <div className={`${s.filters} ${!getIsStaff() ? s.oneFilter : ""}`}>
            <div className={s.selectWrapper}>
              <div className={s.selectIconWrapper}>
                <GlobalSvgSelector id="status-select-icon" />
              </div>
              <MultiSelect
                isClearable={true}
                value={returnSelectedStatus}
                options={pixelApiStatusOptions.map((option) => {
                  return {
                    ...option,
                    label: t(option.label),
                  };
                })}
                setSelectedOptions={(option) => {
                  if (option !== null) {
                    updateSearchParams(status, option?.value);
                  } else {
                    updateSearchParams(status, "", true);
                  }
                }}
                placeholder={t("status")}
                isMulti={false}
              />
            </div>

            {(getIsStaff() || getIsAllowedStatisticsAccess()) && (
              <div className={s.selectWrapper}>
                <div className={s.selectIconWrapper}>
                  <GlobalSvgSelector id="support-handle-select-icon" />
                </div>
                <MultiSelect
                  isClearable={true}
                  value={returnSelectedSupportHandle}
                  options={pixelApiHandleSupportOptions.map((option) => {
                    return {
                      ...option,
                      label: t(option.label),
                    };
                  })}
                  setSelectedOptions={(option) => {
                    if (option !== null) {
                      updateSearchParams(is_support_handled, option?.value);
                    } else {
                      updateSearchParams(is_support_handled, "", true);
                    }
                  }}
                  placeholder={t("support_note")}
                  isMulti={false}
                />
              </div>
            )}

            {(getIsStaff() || getIsAllowedStatisticsAccess()) &&
              hasNonEmptyParams(searchParams) && (
                <div className={s.cleanBtnContainer}>
                  <button
                    className={s.blueBorderBtn}
                    onClick={clearSearchParams}
                  >
                    {t("clean_button")}
                  </button>
                </div>
              )}
          </div>
        </div>

        <div className={s.pageInnerContent}>
          {totalCount === 0 && !pixelApiListPending ? (
            <EmptyList />
          ) : pixelApiListPending ? (
            <section className={s.body}>
              <table>
                <thead>
                  <tr>
                    <th>{t("pixel")}</th>
                    <th>{t("token")}</th>
                    {(getIsStaff() || getIsAllowedStatisticsAccess()) && (
                      <>
                        <th>{t("offer")}</th>
                      </>
                    )}
                    <th>{t("status")}</th>
                    {(getIsStaff() || getIsAllowedStatisticsAccess()) && (
                      <>
                        <th>{t("email")}</th>
                        <th>{t("support_note")}</th>
                      </>
                    )}
                    <th></th>
                  </tr>
                </thead>
              </table>
              <div className={s.skeletonWrapper}>
                <CustomSkeleton styles={s.skeletonTable} />
              </div>
            </section>
          ) : (
            <section className={s.body}>
              <table>
                <thead>
                  <tr>
                    <th>{t("pixel")}</th>
                    <th>{t("token")}</th>
                    {(getIsStaff() || getIsAllowedStatisticsAccess()) && (
                      <>
                        <th>{t("offer")}</th>
                      </>
                    )}
                    <th>{t("status")}</th>
                    {(getIsStaff() || getIsAllowedStatisticsAccess()) && (
                      <>
                        <th>{t("email")}</th>
                        <th>{t("support_note")}</th>
                      </>
                    )}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {pixelApiList.map((item) => (
                    <PixelApiTd
                      key={item.id}
                      {...item}
                      onDeleteHandler={onDeleteHandler}
                    />
                  ))}
                </tbody>
              </table>
            </section>
          )}
        </div>

        <div
          className={`${s.paginationContainer} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`}
        >
          {!pixelApiListPending && totalCount > 0 && (
            <div className={s.newRecordsCount}>
              {t("total")}: {!pixelApiListPending && <span>{totalCount}</span>}
            </div>
          )}
          {totalCount > 25 && (
            <Pagination
              currentPage={parseInt(pageValue) || 1}
              setCurrentPage={(page) => {
                updateSearchParams(params.page, page);
              }}
              total={totalCount}
              limit={25}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PixelApi;
