import React from "react";
import { SnackbarProvider, useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { MaterialDesignContent } from "notistack";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "nowrap",
  "&.notistack-MuiContent-success": {
    backgroundColor: "#149150",
    borderRadius: 35,
    padding: "0px 15px 0px 15px",
    fontSize: 13,
  },
  "&.notistack-MuiContent-default": {
    borderRadius: 35,
    padding: "0px 15px 0px 15px",
    fontSize: 13,
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: "#d32f2f",
    borderRadius: 35,
    padding: "0px 15px 0px 15px",
    fontSize: 13,
  },
  "&.notistack-MuiContent-warning": {
    // backgroundColor: "#d32f2f",
    borderRadius: 35,
    padding: "0px 15px 0px 15px",
    fontSize: 13,
  },
  "&.notistack-MuiContent-info": {
    backgroundColor: "#476CFF",
    borderRadius: 35,
    padding: "0px 15px 0px 15px",
    fontSize: 13,
  },
}));

function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)} color="inherit">
      <CloseIcon />
    </IconButton>
  );
}

const CustomSnackbarProvider = ({ children }) => {
  return (
    <SnackbarProvider
      action={(snackbarKey) => (
        <SnackbarCloseButton snackbarKey={snackbarKey} />
      )}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        default: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
      }}
      maxSnack={3}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default CustomSnackbarProvider;
