export const params = {
  ordering: "ordering",
  page_size: "page_size",
  page: "page",
  domain: "domain",
  domain_address: "domain_address",
  geo: "geo",
  sub7: "sub7",
  created_at_after: "created_at_after",
  created_at_before: "created_at_before",
  search: "search",
  status: "status",
  is_lead: "is_lead",
  is_sale: "is_sale",
  is_installed: "is_installed",
  is_support_handled: "is_support_handled",
  purpose: "purpose",
  application_name: "application_name",
  sort: "sort",
  user: "user",
  ip_address: "ip_address",
  flow: "flow",
  country_code: "country_code",
  os: "os",
  date_time_before: "date_time_before",
  date_time_after: "date_time_after",
  email: "email",
  postback_type: "postback_type",
  postback_time: "postback_time",
};

export const pixelApiHandleSupportOptions = [
  { value: "true", label: "marked" },
  { value: "false", label: "unmarked" },
];

export const pixelApiStatusOptions = [
  { value: "true", label: "active" },
  { value: "false", label: "inactive" },
];

export const sortOrderPrefixes = {
  asc: "",
  desc: "-",
};

export const clicksStatsOrderingList = [
  { ordering: "geo", name: "geo_column" },
  { ordering: "domain", name: "domain_column" },
  { ordering: "app__name", name: "application" },
  { ordering: "sub7", name: "sub7" },
  { ordering: "is_installed", name: "install_column" },
  { ordering: "is_lead", name: "lead" },
  { ordering: "is_sale", name: "sale" },
  { ordering: "created_at", name: "date" },
];

export const usersStatsOrderingList = [
  { ordering: "geo", name: "geo" },
  { ordering: "app__name", name: "application" },
  { ordering: "app__bundle", name: "bundle" },
  { ordering: "email", name: "email" },
  { ordering: "offer", name: "offer" },
  { ordering: "total_clicks", name: "сlicks_column" },
  { ordering: "total_installs", name: "installs_column" },
  { ordering: "total_leads", name: "leads_column" },
  { ordering: "total_sales", name: "sales_column" },
];

export const balancesOrderingList = [
  { ordering: "email", name: "email" },
  { ordering: "offer", name: "offer" },
  { ordering: "wallet__balance", name: "balance" },
];

export const postbackStatusList = [
  { value: "lead", label: "lead" },
  { value: "sale", label: "sale" },
];

export const userTypeList = [
  { value: false, label: "Обычный" },
  { value: true, label: "Партнер" },
];

export const commonClicksStatsOrderingList = [
  { ordering: "geo", name: "geo" },
  { ordering: "app__name", name: "application" },
  { ordering: "domain", name: "domain" },
  { ordering: "sub7", name: "sub7" },
  { ordering: "total_clicks", name: "сlicks_column" },
  { ordering: "unique_clicks", name: "unique_clicks" },
  { ordering: "total_installs", name: "installs_column" },
  { ordering: "total_leads", name: "leads_column" },
  { ordering: "total_sales", name: "sales_column" },
];

export const geoAppStatsOrderingList = [
  { ordering: "geo", name: "Гео" },
  { ordering: "app__name", name: "Приложение" },
  { ordering: "app__bundle", name: "Бандл" },
  { ordering: "total_clicks", name: "Clicks" },
  { ordering: "total_installs", name: "Installs" },
  { ordering: "total_leads", name: "Leads" },
  { ordering: "total_sales", name: "Sales" },
];

export const statisticsStatusOptions = [
  { value: "is_lead", label: "lead" },
  { value: "is_sale", label: "sale" },
  { value: "is_installed", label: "install" },
];

export const postbackTypeStatusOptions = [
  { value: "lead", label: "lead" },
  { value: "sale", label: "sale" },
  { value: "installed", label: "install" },
];

export const postbackLogsStatusOptions = [
  { value: "success", label: "success" },
  { value: "error", label: "error" },
];

export const sortDateOptions = [
  { value: "today", label: "Сегодня" },
  { value: "yesterday", label: "Вчера" },
  { value: "this_week", label: "На этой неделе" },
  { value: "last_7_days", label: "Последние 7 дней" },
  { value: "this_month", label: "В этом месяце" },
  { value: "previous_month", label: "Предыдущий месяц" },
  { value: "last_30_days", label: "Последние 30 дней" },
  { value: "custom_date", label: "Ваша дата" },
];

// export const statisticsStatus = {
//     is_lead: "is_lead",
//     is_sale: "is_sale",
//     is_installed: "is_installed",
// };

//
// export const statisticsStatus = {
//     install: 'install',
//     lead: 'lead',
//     sale: 'sale',
//     open: 'open',
// }
// export const ordering = {
//     country: 'country__name',
//     pwa: 'pwa__name',
//     domain: 'domain',
//     click_id: 'click_id',
//     is_install: 'is_install',
//     is_lead: 'is_lead',
//     is_sale: 'is_sale',
//     updated_at: 'updated_at',
//     created_at: 'created_at',
//     clicks: 'clicks',
//     installs: 'installs',
//     leads: 'leads',
//     amount: 'amount',
//     sales: 'sales',
//     status: 'status',
//     is_open: "is_open",
// }
//
// export const balanceOrdering = {
//     user: "user__username",
//     amount: "amount"
// }
