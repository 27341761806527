import React from "react";
import { useSelector } from "react-redux";
import { financeSelector } from "../../../../redux/slices/financeSlice";
import { convertDate } from "../../../../utils/helpers/formatTime";
import EmptyList from "../../../../components/Blocks/EmptyList/EmptyList";
import CustomSkeleton from "../../../../components/CustomSkeleton/CustomSkeleton";
import s from "../transactions.module.scss";
import { useTranslation } from "react-i18next";

const RefillOption = () => {
  const {
    transactionsLoading,
    transactionsList,
    totalCount,
    transactionsGlobalTotal,
  } = useSelector(financeSelector);

  const { t } = useTranslation();

  return (
    <>
      {totalCount === 0 && !transactionsLoading ? (
        <EmptyList />
      ) : transactionsLoading ? (
        <section className={s.body}>
          <table>
            <thead>
              <tr>
                <th>{t("date")}</th>
                <th>{t("ammount")}</th>
                <th>Hash {t(" transactions")}</th>
              </tr>
            </thead>
          </table>
          <div className={s.skeletonWrapper}>
            <CustomSkeleton styles={s.skeletonTable} />
          </div>
        </section>
      ) : (
        <section className={s.body}>
          <table>
            <thead>
              <tr>
                <th>{t("date")}</th>
                <th>{t("ammount")}</th>
                <th>Hash {t(" transactions")}</th>
              </tr>
            </thead>
            <tbody>
              {transactionsList.map(
                ({
                  id,
                  created_at,
                  amount,
                  crypto_currency_transaction,
                  description,
                }) => (
                  <tr key={id}>
                    <td className={s.dateTd}>
                      <span>{convertDate(created_at, "DMY")}</span>{" "}
                      <span>{convertDate(created_at, "HM")}</span>
                    </td>
                    <td>$ {amount}</td>
                    <td>
                      {crypto_currency_transaction?.txid ? (
                        <a
                          target="_blank"
                          href={`https://tronscan.org/#/transaction/${crypto_currency_transaction?.txid}`}
                          className={s.hashContent}
                          rel="noreferrer"
                        >
                          {crypto_currency_transaction?.txid}
                        </a>
                      ) : (
                        description
                      )}
                    </td>
                  </tr>
                ),
              )}
            </tbody>
            <tfoot>
              <tr>
                <th>{t("total")}</th>
                <th>$ {transactionsGlobalTotal}</th>
                <th></th>
              </tr>
            </tfoot>
          </table>
        </section>
      )}
    </>
  );
};

export default RefillOption;
