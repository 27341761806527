import React, { useRef, useState } from "react";
import s from "./newAuth.module.scss";
import GlobalSvgSelector from "../../../components/GlobalSvgSelector/GlobalSvgSelector";
import { useFormik } from "formik";
import * as Yup from "yup";
import { authSelector, login } from "../../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { noActiveAccountFound } from "../../../utils/vars/errorMessages";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setIsNotified } from "../../../storage/storage";

const NewAuth = () => {
  const [isShowedPassword, setShowedPassword] = useState(false);
  const passwordInputRef = useRef(null);

  const { loading } = useSelector(authSelector);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const handleShowPasswordClick = (e) => {
    e.preventDefault();
    setShowedPassword(!isShowedPassword);

    if (passwordInputRef.current) {
      passwordInputRef.current.focus();
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      enterLimit: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("incorrectEmailFormat"))
        .required(t("requiredField")),
      password: Yup.string().required(t("requiredField")),
    }),
    onSubmit: async (form) => {
      const loginResult = await dispatch(
        login({
          password: form.password.trim(),
          email: form.email,
        }),
      );

      if (loginResult?.payload?.status === 200) {
        setIsNotified("false");
        localStorage.setItem("accessToken", loginResult?.payload?.data?.access);
        localStorage.setItem(
          "refreshToken",
          loginResult?.payload?.data?.refresh,
        );
        window.history.pushState({}, "", "/app-catalog");
        window.location.reload();
      } else if (loginResult?.payload?.status === 429) {
        formik.setFieldError("enterLimit", t("enterLimitError"));
      }

      if (loginResult?.payload?.data?.detail === noActiveAccountFound) {
        formik.setFieldError("email", " ");
        formik.setFieldError("password", t("invalidCredentialsError"));
      }
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  });

  return (
    <form className={s.authFormWrapper} onSubmit={formik.handleSubmit}>
      <div className={s.welcomeBlock}>
        {t("welcomeBack")}
        <Link to="/auth/login">
          <GlobalSvgSelector id="new-logo-icon" />
        </Link>
      </div>

      <div className={s.formDescription}>{t("enterAccDesc")}</div>

      <div className={s.formFieldsWrapper}>
        <div className={s.fieldWrapper}>
          <p className={s.fieldDescription}>{t("email")}</p>
          <div
            className={`${s.inputWrapper} ${
              formik.touched.email && formik.errors.email ? s.wrapperError : ""
            }`}
          >
            <input
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`${s.input} ${
                formik.touched.email && formik.errors.email ? s.error : ""
              }`}
              type="text"
              placeholder={t("enterEmail")}
            />
          </div>
          {formik.touched.email && formik.errors.email ? (
            <div className={s.errorMessage}>{formik.errors.email}</div>
          ) : null}
        </div>
        <div className={s.fieldWrapper}>
          <p className={s.fieldDescription}>{t("password")}</p>
          <div
            className={`${s.inputWrapper} ${
              formik.touched.password && formik.errors.password
                ? s.wrapperError
                : ""
            }`}
          >
            <button
              type="button"
              className={s.showPasswordBtn}
              onClick={handleShowPasswordClick}
            >
              <GlobalSvgSelector
                id={
                  isShowedPassword ? "show-password-icon" : "hide-password-icon"
                }
              />
            </button>
            <input
              name="password"
              value={formik.values.password}
              ref={passwordInputRef}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`${s.input} ${s.password} ${
                formik.touched.password && formik.errors.password ? s.error : ""
              }`}
              type={isShowedPassword ? "text" : "password"}
              placeholder={t("enterPassword")}
            />
          </div>
          {formik.touched.password && formik.errors.password ? (
            <div className={s.errorMessage}>{formik.errors.password}</div>
          ) : null}
          {formik.errors.enterLimit ? (
            <div className={s.errorMessage} style={{ textAlign: "center" }}>
              {formik.errors.enterLimit}
            </div>
          ) : null}
        </div>
        <div className={s.resetPassword}>
          <Link to="/reset/email-confirm">{t("forgotPassword")}</Link>
        </div>
      </div>
      <div className={s.buttonWrapper}>
        <button className={s.enterBtn} type="submit" disabled={loading}>
          {t("enterAcc")}
        </button>
      </div>
    </form>
  );
};

export default NewAuth;
