import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import ru from "../locales/ru.json";
import en from "../locales/en.json";
import ua from "../locales/ua.json";
import { language } from "../storage/storage";

i18n.use(initReactI18next).init({
  resources: {
    ru: { translation: ru },
    en: { translation: en },
    ua: { translation: ua },
  },
  lng: language,
  // lng: "en",
  // lng: "ru",
  // lng: "ua",
  fallbackLng: "ua",
  interpolation: { escapeValue: false },
  // debug: true, // Увімкніть для дебагу в розробці
});
