import s from "./unauthorizedPopup.module.scss";
import React, { useEffect } from "react";
import successIcon from "../../../../assets/img/unauthorizedSuccessIcon.png";
import errorIcon from "../../../../assets/img/unauthorizedErrorIcon.png";
import { useTranslation } from "react-i18next";

const UnauthorizedPopup = ({
  active,
  action,
  description,
  title,
  confirmBtnText,
  email,
  support,
  error,
}) => {
  const { t } = useTranslation();

  const handleClosePopup = (event) => {
    if (event.target.classList.contains(s.popupWrapper)) {
      action();
    }
  };

  useEffect(() => {
    const handleEscClose = (e) => {
      if (e.key === "Escape") {
        action();
        if (document.activeElement) {
          document.activeElement.blur();
        }
      }
    };
    const handleEnterPress = (e) => {
      if (e.key === "Enter") {
        if (document.activeElement) {
          document.activeElement.blur();
        }
        action();
      }
    };
    if (active) {
      document.addEventListener("keydown", handleEscClose);
      document.addEventListener("keydown", handleEnterPress);
    }

    return () => {
      document.removeEventListener("keydown", handleEscClose);
      document.removeEventListener("keydown", handleEnterPress);
    };
  }, [active]);

  return (
    <div
      className={`${s.popupWrapper} ${active ? s.active : ""}`}
      onClick={handleClosePopup}
    >
      <div className={active ? `${s.popup} ${s.active}` : s.popup}>
        <div className={s.bodyContainer}>
          <span className={s.iconWrapper}>
            {error ? (
              <img src={errorIcon} alt="error check" />
            ) : (
              <img src={successIcon} alt="success check" />
            )}
          </span>
          <p className={s.title}>{title}</p>
          <p className={s.description}>
            {description}{" "}
            {support && (
              <span className={s.support}>
                {t("contact")}{" "}
                <a href="https://t.me/yaroslava_support_wwa">
                  {t("supportPopupMessage")}
                </a>
              </span>
            )}
          </p>
          {email && <p className={s.spamEmail}>{t("spamCheck")}</p>}
        </div>
        <div className={s.footer}>
          <button onClick={action} className={s.primaryBtn} type="button">
            {confirmBtnText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UnauthorizedPopup;
