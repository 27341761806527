import React, { useRef, useState } from "react";
import s from "./gradientSearch.module.scss";
import GlobalSvgSelector from "../../GlobalSvgSelector/GlobalSvgSelector";
import { useTranslation } from "react-i18next";

const GradientSearch = ({ value, setValue }) => {
  const searchRef = useRef(null);
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const { t } = useTranslation();

  const handleSearchFocus = () => {
    setIsSearchFocused(true);
  };

  const handleSearchBlur = () => {
    setIsSearchFocused(false);
  };

  return (
    <div className={s.searchInputWrapper}>
      <span>
        <GlobalSvgSelector id="new-search-icon" />
      </span>
      <input
        type="text"
        className={s.searchInput}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        ref={searchRef}
        onFocus={handleSearchFocus}
        onBlur={handleSearchBlur}
        placeholder={t("search_placeholder")}
      />
    </div>
  );
};

export default GradientSearch;
