import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { switchSupportHandle } from "../../../../redux/slices/pixelApiSlice";
import s from "../pixelApi.module.scss";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Link } from "react-router-dom";
import MuiTooltip from "../../../../components/UiKit/MuiTooltip/MuiTooltip";
import GlobalSvgSelector from "../../../../components/GlobalSvgSelector/GlobalSvgSelector";
import NewConfirmPopup from "../../../../components/UiKit/Popups/NewConfirmPopup/NewConfirmPopup";
import { useTranslation } from "react-i18next";
import {
  getIsAllowedStatisticsAccess,
  getIsStaff,
} from "../../../../storage/storage";

const PixelApiTd = ({
  id,
  pixel_id,
  token,
  onDeleteHandler,
  status,
  offer,
  email,
  is_support_handled,
}) => {
  const [active, setActive] = useState(false);

  const [isSupportHandled, setSupportHandle] = useState(is_support_handled);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const switchHandleSupportHandler = async (e) => {
    const dataObj = { id, is_support_handled: e.target.checked };
    const switchDetails = await dispatch(switchSupportHandle(dataObj));

    if (switchDetails?.payload?.status === 200) {
      setSupportHandle((prev) => !prev);
    }
  };

  return (
    <tr>
      <td>{pixel_id}</td>
      <td className={s.tokenTd}>
        <a
          className={s.tokenLink}
          href={`https://developers.facebook.com/tools/debug/accesstoken/?access_token=${token}`}
          target="_blank"
          rel="noreferrer"
        >
          <span className={s.token}>{token}</span>
          <GlobalSvgSelector id="follow-link-icon" />
        </a>
      </td>
      {(getIsStaff() || getIsAllowedStatisticsAccess()) && <td>{offer}</td>}
      <td>
        {status ? (
          <span className={s.trueStatus}>{t("active")}</span>
        ) : (
          <span className={s.falseStatus}>{t("inactive")}</span>
        )}
      </td>
      {(getIsStaff() || getIsAllowedStatisticsAccess()) && (
        <>
          <td>{email}</td>
          <td>
            <FormControlLabel
              style={{
                margin: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              control={
                <Checkbox
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "white",
                    },
                  }}
                  checked={isSupportHandled}
                  onChange={switchHandleSupportHandler}
                  color="primary"
                />
              }
              sx={{ color: "white" }}
            />
          </td>
        </>
      )}
      <td>
        <NewConfirmPopup
          icon={<GlobalSvgSelector id="popup-remove-icon" />}
          active={active}
          setActive={setActive}
          action={() => onDeleteHandler(id)}
          confirmBtnText={t("button_remove")}
          title={t("pixel_api_removing")}
          description={t("want_to_remove_pixel_api")}
        />
        <div className={s.actionsWrapper}>
          <div className={s.actionsContainer}>
            <div className={s.actions}>
              <Link
                to={`/pixel-api/edit/${id}`}
                className={s.manageTableFillBtn}
              >
                <MuiTooltip title={t("edit_pixel_api")}>
                  <GlobalSvgSelector id="old-edit-icon" />
                </MuiTooltip>
              </Link>
              <button
                className={s.removeBtn}
                onClick={() => setActive(!active)}
              >
                <MuiTooltip title={t("remove_pixel_api")}>
                  <GlobalSvgSelector id="new-remove-icon" />
                </MuiTooltip>
              </button>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default PixelApiTd;
