import React from "react";
import s from "./upload.module.scss";
import GlobalSvgSelector from "../../../../../components/GlobalSvgSelector/GlobalSvgSelector";
import { Link } from "react-router-dom";
import PrimaryBtn from "../../../../../components/UiKit/Buttons/PrimaryBorderBtn/PrimaryBorderBtn";
import { useSelector } from "react-redux";
import { prelandingSelector } from "../../../../../redux/slices/prelandingSlice";
import { useTranslation } from "react-i18next";

const Upload = ({ formik }) => {
  const { createPrelandPending } = useSelector(prelandingSelector);

  const { t } = useTranslation();

  const { prelanding_file, name } = formik.values;

  return (
    <form onSubmit={formik.handleSubmit} className={s.uploadPrelandWrapper}>
      <div className={s.formContentWrapper}>
        <div className={s.formFieldsWrapper}>
          <div className={s.fieldWrapper}>
            <p className={s.fieldName}>{t("prelanding_name")}</p>
            <input
              type="text"
              name="name"
              className={`${s.textInput} ${
                formik.touched.name && formik.errors.name ? s.error : ""
              }`}
              placeholder={t("placehoder_type_text")}
              value={name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className={s.error}>{formik.errors.name}</div>
            ) : null}
          </div>
          <div className={s.fieldWrapper}>
            <label
              htmlFor="upload-button"
              className={`${s.uploadPrelandBtn} ${
                formik.touched.prelanding_file && formik.errors.prelanding_file
                  ? s.zipArchiveError
                  : ""
              }`}
            >
              <GlobalSvgSelector id="archive-download-icon" />
              <span>{prelanding_file?.name || t("upload")}</span>
            </label>
            {formik.touched.prelanding_file && formik.errors.prelanding_file ? (
              <div className={s.error}>{formik.errors.prelanding_file}</div>
            ) : null}
            <input
              id="upload-button"
              type="file"
              style={{ display: "none" }}
              onChange={(e) => {
                console.log(e.target.files[0]);
                formik.setFieldValue("prelanding_file", e.target.files[0]);
              }}
              accept=".zip"
            />
          </div>
        </div>
      </div>
      <div className={s.buttons}>
        <Link className={s.whiteBorderBtn} to="/prelandings">
          {t("button_cancel")}
        </Link>
        <PrimaryBtn
          text={t("create")}
          submit={true}
          pending={createPrelandPending}
          className={s.customPrimaryBtn}
        />
      </div>
    </form>
  );
};

export default Upload;
