import React, { useCallback, useEffect } from "react";
import {
  fetchAllPrelandings,
  fetchWheelStylesList,
  prelandingSelector,
  setAllPrelandingsList,
} from "../../../../../../../redux/slices/prelandingSlice";
import { useDispatch, useSelector } from "react-redux";
import s from "./choosePrelanding.module.scss";
import { FormControlLabel, Radio } from "@mui/material";
import {
  flowSettingsSelector,
  incrementVisiblePrelandings,
  setCurrentEditPrelandingObj,
} from "../../../../../../../redux/slices/flowSettingsSlice";
import { styled } from "@mui/material/styles";
import PrelandingCard from "../../PrelandingCard/PrelandingCard";
import { Link } from "react-router-dom";
import PrimaryBtn from "../../../../../../../components/UiKit/Buttons/PrimaryBorderBtn/PrimaryBorderBtn";
import CustomSkeleton from "../../../../../../../components/CustomSkeleton/CustomSkeleton";
import GlobalSvgSelector from "../../../../../../../components/GlobalSvgSelector/GlobalSvgSelector";
import useCustomSnackbar from "../../../../../../../hooks/useCustomSnackbar";
import { snackbar_success } from "../../../../../../../utils/vars/staticVariables";
import { useTranslation } from "react-i18next";

const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  color: "white",
  "& .MuiRadio-root": {
    color: "white",
  },
  "& .MuiRadio-root.Mui-checked": {
    color: theme.palette.primary.main,
  },
}));

const ChoosePrelanding = ({ formik, content, setContent }) => {
  const {
    flowObj,
    setFlowPrelandingPending,
    fetchFlowByIdPending,
    visiblePrelandings,
  } = useSelector(flowSettingsSelector);

  const {
    wheelStylesLoading,
    wheelStylesList,
    allPrelandingsPending,
    allPrelandingsList,
  } = useSelector(prelandingSelector);

  const dispatch = useDispatch();

  const showSnackbar = useCustomSnackbar();

  const { t } = useTranslation();

  useEffect(() => {
    if (wheelStylesList.length === 0) {
      dispatch(fetchWheelStylesList());
    }

    if (allPrelandingsList.length === 0) {
      dispatch(fetchAllPrelandings());
    }
  }, [dispatch]);

  useEffect(() => {
    if (formik.values.prelanding_id) {
      const updatedList = [...allPrelandingsList];
      const index = updatedList.findIndex(
        (item) => item.id === formik.values.prelanding_id,
      );

      if (index !== -1) {
        const [selectedItem] = updatedList.splice(index, 1);
        updatedList.unshift(selectedItem);

        dispatch(setAllPrelandingsList(updatedList));
      }
    }
  }, [flowObj, allPrelandingsPending, dispatch]);

  const handleShowMore = () => {
    const remainingItems = allPrelandingsList.length - visiblePrelandings;
    const itemsToLoad = remainingItems >= 8 ? 8 : remainingItems;

    dispatch(incrementVisiblePrelandings(itemsToLoad));
    showSnackbar(
      `${t("was_loaded")} ${itemsToLoad} ${t("records")}`,
      snackbar_success,
    );
  };

  const updateEditPrelandingObj = useCallback(
    (obj) => {
      dispatch(setCurrentEditPrelandingObj(obj));
    },
    [dispatch],
  );

  const skeletons = (styles) => {
    return [...new Array(8)].map((_, index) => (
      <div className={s.skeletonCardWrapper} key={index}>
        <CustomSkeleton styles={styles} />
      </div>
    ));
  };

  return (
    <form className={s.choosePrelandingWrapper} onSubmit={formik.handleSubmit}>
      <div className={s.pageInnerContent}>
        <div className={s.formContainer}>
          <div className={s.radioInputWrapper}>
            <CustomFormControlLabel
              value={null}
              onClick={() => formik.setFieldValue("prelanding_id", null)}
              control={<Radio checked={formik.values.prelanding_id === null} />}
              label={t("disabled_prelanding")}
            />
          </div>
          <div className={s.prelandingCardsWrapper}>
            {wheelStylesLoading || allPrelandingsPending
              ? skeletons(s.skeletonCard)
              : allPrelandingsList
                  .slice(0, visiblePrelandings)
                  .map((item) => (
                    <PrelandingCard
                      obj={item}
                      updateEditPrelandingObj={updateEditPrelandingObj}
                      setContent={setContent}
                      key={item.id}
                      {...item}
                      wheelStylesList={wheelStylesList}
                      formik={formik}
                      currentPreland={formik.values.prelanding_id}
                    />
                  ))}
          </div>
        </div>
      </div>
      <div className={s.footer}>
        {visiblePrelandings < allPrelandingsList.length && (
          <div className={s.loadCardsBtnContainer}>
            <button
              className={s.primaryBtn}
              type="button"
              onClick={handleShowMore}
            >
              <GlobalSvgSelector id="load-circle-arrow" />
              {t("show_more")}
            </button>
          </div>
        )}

        <div className={s.buttons}>
          <Link className={s.whiteBorderBtn} to="/my-flows">
            {t("button_cancel")}
          </Link>
          <PrimaryBtn
            text={t("set")}
            submit={true}
            pending={setFlowPrelandingPending || fetchFlowByIdPending}
            className={s.customPrimaryBtn}
          />
        </div>
      </div>
    </form>
  );
};

export default ChoosePrelanding;
