import React from "react";
import { Outlet } from "react-router-dom";
import s from "./authLayout.module.scss";
import blurredAuthBgBL from "../../assets/img/blurredAuthBgBL.png";
import blurredAuthBgTR from "../../assets/img/blurredAuthBgTR.png";
import iphonesImg from "../../assets/img/iphonesAuthBgImg.png";

const AuthLayout = () => {
  return (
    <div className={s.authWrapper}>
      <img src={blurredAuthBgTR} className={s.bgImgBL} />
      <img src={blurredAuthBgBL} className={s.bgImgTR} />
      <div className={s.authContentWrapper}>
        <div className={s.contentContainer}>
          <img src={iphonesImg} className={s.iphonesImg} draggable="false" />
          <Outlet />
        </div>
        <div className={s.companyRightsWrapper}>
          WWA © 2024. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
