import { jwtDecode } from "jwt-decode";
import { languageOptions } from "../components/partials/LanguageDropdown/LanguageDropdown";

export const getAccessToken = () => {
  const impersonateToken = getImpersonateUserToken();

  if (
    decodeJwt()?.user?.is_staff ||
    decodeJwt()?.user?.allow_access_to_statistics
  ) {
    if (impersonateToken) {
      return impersonateToken;
    } else {
      return localStorage.getItem("accessToken");
    }
  } else {
    localStorage.removeItem("impersonateUserToken");
    return localStorage.getItem("accessToken");
  }
};

export const getImpersonateUserToken = () => {
  return localStorage.getItem("impersonateUserToken");
};

export const getRefreshToken = () => {
  return localStorage.getItem("refreshToken");
};

export const getAccessTokenUser = () => {
  const token = localStorage.getItem("accessToken");

  if (typeof token === "string") {
    return jwtDecode(token);
  }
  return null;
};

export const decodeJwt = () => {
  const token = localStorage.getItem("accessToken");

  if (typeof token === "string") {
    return jwtDecode(token);
  }
  return null;
};

export const getUser = () => {
  const token = getAccessToken();

  if (typeof token === "string") {
    return jwtDecode(token);
  }
  return null;
};

export const removeTokens = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("impersonateUserToken");
};

export const removeItem = (key) => {
  localStorage.removeItem(key);
};

export const getIsStaff = () => {
  // return getUser()?.user?.is_staff;
  return getUser()?.user?.is_staff;
};

export const getIsSuperuser = () => {
  // return getUser()?.user?.is_superuser;
  return getUser()?.user?.is_superuser;
};

export const getIsAllowedStatisticsAccess = () => {
  // return getUser()?.user?.allow_access_to_statistics;
  return getUser()?.user?.allow_access_to_statistics;
};

export const setSidebarWidth = (param) => {
  localStorage.setItem("sidebarWidth", param);
};

export const getSidebarWidth = () => {
  return localStorage.getItem("sidebarWidth");
};

const getLanguage = () => {
  if (typeof localStorage !== "undefined") {
    const lang = localStorage.getItem("language");
    return languageOptions.includes(lang) ? lang : "ua";
  }
  return "ua";
};

export const language = getLanguage();

export const setLanguage = (lang) => {
  localStorage.setItem("language", lang);
};

const getIsNotified = () => {
  return localStorage.getItem("is_notified");
};
export const setIsNotified = (value) => {
  localStorage.setItem("is_notified", value);
};
export const isNotified = getIsNotified();
